declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

export const Utils = {
  roundNumber(value: number): number {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  },

  getUserResultsByUnit(unit: string, user: any) {
    if (!unit || unit === 'Pts') {
      return `${this.roundNumber(user.totalPoints)} pts`;
    }

    if (unit === 'DTR') {
      if (user.sportUnit) {
        switch (user.sportUnit) {
          case 'miles':
            return `${user.totalAmount} mi.`;
          case 'meters':
            return `${user.totalAmount} m.`;
          case 'minutes':
            return `${user.totalAmount} min.`;
          case 'seconds':
            return `${user.totalAmount} sec.`;
          default:
            return user.totalAmount;
        }
      } else {
        return `${this.roundNumber(user.totalPoints)} pts`;
      }
    }
  },

  isIE() {
    const ua = navigator.userAgent;

    return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
  },

  export(data: Blob, fileName: string, format: string) {
    let formatType: string = '';
      switch (format) {
        case 'csv':
          formatType = 'text/csv';
          break;
        case 'xlsx':
          formatType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          break;
        default:
          break;
      }
      const blob = new Blob([data], { type: formatType });
      if (!this.isIE()) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = `${fileName}.${format}`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      } else {
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, `${fileName}.${format}`);
        }
      }
  }
};
