import { SessionService } from './SessionService';

const API_URL = process.env.REACT_APP_API_URL;

const getListOfLeagueNames = async (seasonId: string) => {
  try {
    const response = await fetch(`${API_URL}/report/season/${seasonId}/league`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: ReportService.js ~ getListOfLeagueNames ~ error', error);
    return { status: 500 };
  }
};

const getListOfMatchupsPerWeek = async (leagueId: string, week?: number) => {
  let params = {
    week: '',
  };

  if (week) {
    params.week = week.toString();
  }

  try {
    const response = await fetch(`${API_URL}/report/league/${leagueId}/matchups?${new URLSearchParams(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: ReportService.js ~ getListOfMatchupsPerWeek ~ error', error);
    return { status: 500 };
  }
};

const getIndividualLeagueLeaderboard = async (
  leagueId: string,
  category?: string,
  activity?: string,
  day?: number,
  standingsFilter?: string
) => {
  let params = {
    category: '',
    activity: '',
    day: '',
    standingsFilter: '',
  };

  if (category) {
    params.category = category;
  }

  if (activity) {
    params.activity = activity;
  }

  if (day) {
    params.day = day.toString();
  }

  if (standingsFilter) {
    params.standingsFilter = standingsFilter;
  }

  try {
    const response = await fetch(`${API_URL}/report/league/${leagueId}/leaderboard/individual?${new URLSearchParams(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: ReportService.js ~ getIndividualLeagueLeaderboard ~ error', error);
    return { status: 500 };
  }
};

const getIndividualLeaderboardFilters = async () => {
  try {
    const response = await fetch(`${API_URL}/report/filters`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: ReportService.js ~ getIndividualLeaderboardFilters ~ error', error);
    return { status: 500 };
  }
};

const getTeamLeagueLeaderboard = async (leagueId: string, teamStandingsFilter: string) => {
  let params = {
    teamStandingsFilter: '',
  };

  if (teamStandingsFilter) {
    params.teamStandingsFilter = teamStandingsFilter;
  }

  try {
    const response = await fetch(`${API_URL}/report/league/${leagueId}/leaderboard/team?${new URLSearchParams(params)}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: ReportService.js ~ getTeamLeagueLeaderboard ~ error', error);
    return { status: 500 };
  }
};

export const ReportService = {
  getListOfLeagueNames,
  getListOfMatchupsPerWeek,
  getIndividualLeagueLeaderboard,
  getIndividualLeaderboardFilters,
  getTeamLeagueLeaderboard,
};
