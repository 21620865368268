import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RegularWeeks, Standings } from 'src/models';
import { InvitationLinkModal, ListModal, QuestionModal, Sidebar } from '../../components';
import MatchupWeekList from '../../components/MatchupWeekList';
import MatchupWeekStandings from '../../components/MatchupWeekStandings';
import { LeaguesService, SessionService } from '../../services';

const MatchupDetails = () => {
  const { seasonId, leagueId } = useParams();
  const [weeks, setWeeks] = useState<Array<any>>([]);
  const [teams, setTeams] = useState<Array<any>>([]);
  const [league, setLeague] = useState<any>(null);
  const [standings, setStandings] = useState<Array<Standings>>([]);
  const [bracketSize, setBracketSize] = useState(0);
  const [regularWeeksBrackets, setRegularWeeksBrackets] = useState<Array<RegularWeeks>>([]);
  const [selectedTeam, setSelectedTeam] = useState<any>(null);
  const [moveToTeam, setMoveToTeam] = useState<any>(null);
  const [selectedLeague, setSelectedLeague] = useState<any>(null);
  const [leagues, setLeagues] = useState<Array<any>>([]);
  const [invitationLink, setInvitationLink] = useState('');
  const [isInvitationLinkModal, setIsInvitationLinkModal] = useState(false);
  const [teamName, setTeamName] = useState('');

  useMemo(() => {
    const getData = async () => {
      const data = await LeaguesService.getLeague(seasonId!, leagueId!);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.results) {
        setLeague(data.results);
        setWeeks(() => [...Array(data.results.regularWeeks.numberOfWeeks).keys()]);
        setBracketSize(Number(data.results.teamLimit / 2));
        setRegularWeeksBrackets(() => data.results.regularWeeksBrackets);
      }

      const dataStandings = await LeaguesService.getLeagueStandings(seasonId!, leagueId!);

      if (dataStandings.status === 500) {
        SessionService.clearSession();
      }

      setTeams([]);
      setStandings([]);
      if (dataStandings.results) {
        dataStandings.results.map((item: any) => {
          setTeams(teams => [...teams, { key: item.team._id, value: item.team.name }]);
        });

        dataStandings.results
          .sort((a: any, b: any) => a.team.name.localeCompare(b.team.name))
          .map((item: any) => {
            setStandings(standings => [
              ...standings,
              {
                _id: '',
                team: {
                  _id: item.team._id,
                  name: item.team.name,
                  photo: item.team.photo,
                  invitationText: item.team.invitationText,
                },
                users: item.team.users.length || 0,
                won: item.won,
                lost: item.lost,
                totalPoints: item.totalPoints,
              },
            ]);
          });
      }
    };

    getData();
  }, [seasonId, leagueId]);

  const getLeagues = async () => {
    const data = await LeaguesService.getLeaguesForMovingTeam(seasonId!, leagueId!);

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data.results) {
      setLeagues(() => data.results);
    }
  };

  const handleMoveTo = (team: { _id: string; name: string; photo: string }) => {
    setMoveToTeam(team);
    getLeagues();
  };

  const handleListModal = (value: boolean) => {
    if (!value) {
      setMoveToTeam(null);
    }
  };

  const handleInvitationLinkModal = (value: any) => {
    if (value) {
      setTeamName(() => value.name);
      setInvitationLink(() => value.invitationText);
      setIsInvitationLinkModal(() => true);
    }
  };

  const handleCopyInvitationLink = (value: string) => {
    navigator.clipboard.writeText(value);
    setIsInvitationLinkModal(() => false);
  };

  const handleCloseInvitationModal = (value: boolean) => {
    setIsInvitationLinkModal(() => value);
  };

  const moveTeam = async () => {
    const data = await LeaguesService.moveTeamToLeague(seasonId!, leagueId!, moveToTeam._id, selectedLeague._id);

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data) {
      setStandings(standings => standings.filter(item => item.team._id !== moveToTeam._id));

      // TODO: Delete matchup with this team if exists instead of reloading page.
      window.location.reload();
    }

    setSelectedLeague(null);
    setMoveToTeam(null);
  };

  const handleLeagueQuestionModal = (value: boolean) => {
    value ? moveTeam() : setSelectedLeague(null);
  };

  const removeTeam = async () => {
    const data = await LeaguesService.removeTeam(seasonId!, leagueId!, selectedTeam._id);

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data) {
      setStandings(standings => standings.filter(item => item.team._id !== selectedTeam._id));

      // TODO: Delete matchup with this team if exists instead of reloading page.
      window.location.reload();
    }

    setSelectedTeam(null);
  };

  const handleRemoveQuestionModal = (value: boolean) => {
    value ? removeTeam() : setSelectedTeam(null);
  };

  return (
    <>
      <div className="flex h-screen overflow-hidden">
        <Sidebar />
        <div className="flex flex-col flex-1 w-0 overflow-hidden">
          <main className="relative flex-1 overflow-y-auto focus:outline-none">
            <div className="py-6">
              <div className="w-full px-4 sm:px-6 md:px-8">
                <div className="flex items-center mt-2 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
                    />
                  </svg>
                  <h1 className="text-xl font-semibold">Regular Season</h1>
                </div>

                <div className="grid grid-cols-1 gap-4">
                  <div className="flex space-x-4">
                    <MatchupWeekStandings
                      data={standings}
                      league={league}
                      onMoveTo={value => handleMoveTo(value)}
                      onOpenLink={value => handleInvitationLinkModal(value)}
                      onRemoveTeam={value => setSelectedTeam(value)}
                    />
                    <div className="w-full">
                      {weeks.map((week, index) => (
                        <MatchupWeekList
                          key={index}
                          seasonWeek={week + 1}
                          teams={teams}
                          bracketSize={bracketSize}
                          regularWeeksBrackets={regularWeeksBrackets}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      {moveToTeam && <ListModal data={leagues} onAdd={value => setSelectedLeague(value)} onClose={value => handleListModal(value)} />}
      {isInvitationLinkModal && (
        <InvitationLinkModal
          teamName={teamName}
          link={invitationLink}
          onCopy={value => handleCopyInvitationLink(value)}
          onClose={value => handleCloseInvitationModal(value)}
        />
      )}
      {selectedLeague && (
        <QuestionModal
          text={`Are you sure you want to move ${moveToTeam.name} to the league ${selectedLeague.name}?`}
          onChange={value => handleLeagueQuestionModal(value)}
        />
      )}
      {selectedTeam && (
        <QuestionModal
          text={`Are you sure you want to remove ${selectedTeam.name} from this league?`}
          onChange={value => handleRemoveQuestionModal(value)}
        />
      )}
    </>
  );
};

export default MatchupDetails;
