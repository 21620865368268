import { SessionService } from './SessionService';

const API_URL = process.env.REACT_APP_API_URL;

const getAllSeasons = async (skip: number, limit: number, type: string) => {
  try {
    const response = await fetch(`${API_URL}/season?${new URLSearchParams({ skip: skip.toString(), limit: limit.toString(), type })}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: SeasonsService.js ~ getAllSeasons ~ error', error);
    return { status: 500 };
  }
};

const getSeasonDetails = async (id: any, skip: number, limit: number) => {
  try {
    const response = await fetch(
      `${API_URL}/season/${id}/league?${new URLSearchParams({ skip: skip.toString(), limit: limit.toString() })}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
        },
      }
    );
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: SeasonsService.js ~ getSeasonDetails ~ error', error);
    return { status: 500 };
  }
};

const newSeason = async (name: string, startDate: string, isPrivate: boolean) => {
  try {
    const response = await fetch(`${API_URL}/season`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify({
        name,
        startDate,
        privateSeason: isPrivate,
      }),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: SeasonsService.js ~ newSeason ~ error', error);
    return { status: 500 };
  }
};

const exportUsers = async (id: string) => {
  try {
    const response = await fetch(`${API_URL}/season/${id}/export`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.blob();
  } catch (error) {
    console.log('🚀 ~ file: SeasonsService.js ~ exportUsers ~ error', error);
    return { status: 500 };
  }
};

const getPrivateSeasonsStats = async () => {
  try {
    const response = await fetch(`${API_URL}/season/statistics`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: SeasonsService.js ~ getPrivateSeasonsStats ~ error', error);
    return { status: 500 };
  }
};

export const SeasonsService = {
  getAllSeasons,
  getSeasonDetails,
  newSeason,
  exportUsers,
  getPrivateSeasonsStats
};
