import React from 'react';
import { usePagination, DOTS } from 'src/hooks';
const Pagination = (props: any) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange!.length < 2) {
    return null;
  }

  let lastPage = paginationRange![paginationRange!.length - 1];

  const onFirst = () => {
    onPageChange(1);
  };

  const onLast = () => {
    onPageChange(lastPage);
  };

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <ul className={`flex list-none ${className}`}>
      <li
        className={`flex items-center h-6 min-w-6 px-2 bg-transparent rounded-lg text-sm text-blue-600 cursor-pointer my-auto mx-1.5 ${
          currentPage === 1 && 'pointer-events-none opacity-60'
        }`}
        onClick={onFirst}>
        <span className='mb-1'>{'<<'}</span>
      </li>
      <li
        className={`flex items-center h-6 min-w-6 px-2 bg-gray-100 rounded-lg text-sm text-blue-600 cursor-pointer my-auto mx-1.5 ${
          currentPage === 1 && 'pointer-events-none opacity-60 bg-gray-100'
        }`}
        onClick={onPrevious}>
        <span className='mb-1'>{'<'}</span>
      </li>
      {paginationRange!.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li
              key={`page${index + 1}`}
              className={`flex items-center text-center h-6 min-w-6 px-2 rounded-lg text-sm text-blue-600 my-auto mx-1.5`}>
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={pageNumber}
            className={`flex items-center text-center h-6 min-w-6 px-2 rounded-lg text-sm cursor-pointer my-auto mx-1.5 ${
              pageNumber === currentPage
                ? 'bg-blue-600 text-white'
                : 'bg-gray-100 text-blue-600 hover:bg-blue-600 hover:text-white transition-colors'
            }`}
            onClick={() => onPageChange(pageNumber)}>
            <span className="mb-0.5">{pageNumber}</span>
          </li>
        );
      })}
      <li
        className={`flex items-center text-center h-6 min-w-6 px-2 bg-gray-100 rounded-lg text-sm text-blue-600 cursor-pointer my-auto mx-1.5 ${
          currentPage === lastPage && 'pointer-events-none opacity-60'
        }`}
        onClick={onNext}>
        <span className='mb-1'>{'>'}</span>
      </li>

      <li
        className={`flex items-center text-center h-6 min-w-6 px-2 bg-transparent rounded-lg text-sm text-blue-600 cursor-pointer my-auto mx-1.5 ${
          currentPage === lastPage && 'pointer-events-none opacity-60'
        }`}
        onClick={onLast}>
        <span className='mb-1'>{'>>'}</span>
      </li>
    </ul>
  );
};

export default Pagination;
