import React from 'react';

interface OptionProps {
  key: string;
  value: string;
}
interface MultiTabSwitchProps {
  options: Array<OptionProps>;
  optionKey: string;
  optionValue: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const MultiTabSwitch: React.FC<MultiTabSwitchProps> = ({ options, optionKey, optionValue, value, onChange, disabled }) => {
  const handleChange = (value: string) => {
    if (!disabled) {
      onChange(value);
    }
  };

  return (
    <div className={`flex flex-row items-center w-full h-[32px] px-[2px] rounded-md bg-gray-400 ${disabled && 'opacity-60'}`}>
      {options.map((item: OptionProps) => (
        <div
          key={item[optionKey as keyof OptionProps]}
          className={`flex flex-row items-center justify-center w-full h-[28px] rounded-md text-white ${
            disabled ? 'pointer-events-none' : 'cursor-pointer'
          } ${value === item[optionValue as keyof OptionProps] ? 'bg-black' : 'bg-transparent'}`}
          onClick={() => handleChange(item[optionValue as keyof OptionProps])}>
          <span className="text-sm">{item[optionKey as keyof OptionProps]}</span>
        </div>
      ))}
    </div>
  );
};

export default MultiTabSwitch;
