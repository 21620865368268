import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { RequireAuth } from './components';
import {
  NotFound,
  SignIn,
  TeamsList,
  UsersList,
  SeasonsList,
  SeasonDetails,
  SeasonNew,
  LeagueNew,
  LeagueEdit,
  MatchupDetails,
  MatchupPlayoffDetails,
  LeagueDetails,
  StatsDetails,
} from './pages';
import DemoDetails from './pages/Demo/DemoDetails';
import DemoNew from './pages/Demo/DemoNew';

// TODO: TeamEdit, UserEdit, SessionEdit, MatchupNew, MatchupEdit

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route element={<RequireAuth />}>
        <Route path="teams" element={<TeamsList />} />
        {/* <Route path="teams/:teamId" element={<TeamEdit />} /> */}
        <Route path="users" element={<UsersList />} />
        {/* <Route path="users/:userId" element={<UserEdit />} /> */}
        <Route path="seasons" element={<SeasonsList />} />
        <Route path="seasons/:seasonId" element={<SeasonDetails />} />
        {/* <Route path="seasons/edit/:seasonId" element={<SessionEdit />} /> */}
        <Route path="seasons/new" element={<SeasonNew />} />
        <Route path="seasons/:seasonId/leagues/new" element={<LeagueNew />} />
        <Route path="seasons/:seasonId/leagues/:leagueId/edit" element={<LeagueEdit />} />
        <Route path="seasons/:seasonId/leagues/:leagueId/matchups" element={<MatchupDetails />} />
        <Route path="seasons/:seasonId/leagues/:leagueId/playoff" element={<MatchupPlayoffDetails />} />
        <Route path="seasons/:seasonId/leagues/:leagueId" element={<LeagueDetails />} />
        <Route path="stats" element={<StatsDetails />} />
        <Route path="demo" element={<DemoDetails />} />
        <Route path="demo/new" element={<DemoNew />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default App;
