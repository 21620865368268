import { format, getYear } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

interface ArrowSwitchProps {
  options: Array<any>;
  optionKey: string;
  optionValue: string;
  optionSub?: string;
  value: number | string;
  state?: number;
  isDateFilter?: boolean;
  isDayFilter?: boolean;
  preventOnNext?: boolean;
  hasTotal?: boolean;
  onPrev: (value: any) => void;
  onNext: (value: any) => void;
  isLoading?: boolean;
}

const ArrowSwitch: React.FC<ArrowSwitchProps> = ({
  options,
  optionKey,
  optionValue,
  optionSub,
  state,
  value,
  isDateFilter,
  isDayFilter,
  preventOnNext,
  hasTotal,
  onPrev,
  onNext,
  isLoading,
}) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (isDateFilter) {
      setSelected(() =>
        options.find(option => format(new Date(option[optionValue]), 'yyyy-MM-dd') === format(new Date(value), 'yyyy-MM-dd'))
      );
    } else if (isDayFilter) {
      setSelected(() => options.find(option => option[optionKey] === value));
    } else {
      setSelected(() => options.find(option => option[optionValue] === value));
    }
  }, [value]);

  const handleLeftMove = () => {
    const index = selected && options.findIndex(option => option[optionKey] === selected[optionKey]);

    if (index! > 0) {
      setSelected(options[index! - 1]);

      if (isDayFilter) {
        onPrev(options[index! - 1][optionKey]);
      } else {
        onPrev(options[index! - 1][optionValue]);
      }
    }
  };

  const handleRightMove = () => {
    const index = selected && options.findIndex(option => option[optionKey] === selected[optionKey]);

    if (!preventOnNext) {
      if (index! < options.length - 1) {
        setSelected(options[index! + 1]);

        if (isDayFilter) {
          onNext(options[index! + 1][optionKey]);
        } else {
          onNext(options[index! + 1][optionValue]);
        }
      }
    } else {
      if (options[index! + 1] && options[index! + 1][optionValue] <= state!) {
        setSelected(options[index! + 1]);
        onNext(options[index! + 1][optionValue]);
      }
    }
  };

  if (isLoading) {
    return <Skeleton height={40} borderRadius={6} />;
  }

  if (options.length === 0) {
    return <></>;
  }

  return (
    <div className="w-full">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center justify-center w-[40px] h-[40px] cursor-pointer" onClick={() => handleLeftMove()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
          </svg>
        </div>

        <div className="flex flex-col items-center">
          {isDateFilter && <span className="uppercase text-xs font-bold">{selected && getYear(selected[optionValue])}</span>}
          <span className="uppercase text-xs font-bold">{selected && selected[optionKey]}</span>
          {selected && optionSub && selected[optionSub] && <span className="text-xs">{selected[optionSub]}</span>}
          {/* {isDayFilter && (
            <span className="text-xs">
              {selected &&
                (selected[optionKey] === 'Total' || selected[optionKey] === 'Weekly Total'
                  ? '-'
                  : `Day ${selected[optionValue]}/${hasTotal ? options.length - 1 : options.length}`)}
            </span>
          )} */}
        </div>

        <div className="flex flex-row items-center justify-center w-[40px] h-[40px] cursor-pointer" onClick={() => handleRightMove()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ArrowSwitch;
