import React, { useId } from 'react';

interface DropDownValues {
  key: string | number;
  value: string | number;
}

interface DropDownProps {
  label: string;
  placeholder: string;
  values: Array<DropDownValues>;
  required?: boolean;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const DropDown: React.FC<DropDownProps> = ({ label, placeholder, values, required, value, onChange, disabled }) => {
  const id: string = useId();
  const isRequired: boolean = required ? true : false;

  if (!values) {
    throw new Error('DropDown values are not implemented');
  }

  return (
    <div
      className={`relative w-full px-3 py-3 -space-y-px border border-gray-300 rounded-md shadow-sm ${
        disabled ? 'bg-gray-200 opacity-50' : 'bg-white'
      }`}>
      <label htmlFor={id} className="block text-xs font-bold">
        {label} <span className="font-normal text-gray-600">{required ? '*' : '(optional)'}</span>
      </label>
      <select
        id={id}
        name={id}
        disabled={disabled}
        className="w-full text-gray-900 placeholder-gray-500 bg-white appearance-none focus:outline-none focus:ring-gray-300 focus:border-black focus:z-10 sm:text-sm disabled:bg-gray-200"
        value={value}
        onChange={e => onChange(e.target.value)}
        required={isRequired}>
        <option value="" disabled>
          {placeholder}
        </option>
        {values.map(option => (
          <option key={option.key} value={option.key}>
            {option.value}
          </option>
        ))}
      </select>
      <div className="absolute right-0 flex items-center px-2 pointer-events-none bottom-4">
        <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
          <path
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
            fillRule="evenodd"></path>
        </svg>
      </div>
    </div>
  );
};

export default DropDown;
