import React from 'react';
import MatchupItem from './MatchupItem';
import Skeleton from 'react-loading-skeleton';
import EmptyState from '../EmptyState';

interface MatchupListProps {
  matchups: Array<any>;
  isLoading?: boolean;
}

const MatchupList: React.FC<MatchupListProps> = ({ matchups, isLoading }) => {
  if (isLoading) {
    return <Skeleton height={200} borderRadius={6} count={3} />;
  }

  if (matchups.length === 0) {
    return <EmptyState title="No matchups" />;
  }

  return (
    <>
      {matchups.map(item => (
        <div key={item._id} className="bg-gray-100 rounded-md">
          <div className="w-full flex flex-row items-center justify-center p-4 mb-[10px]">
            <MatchupItem matchup={item?.totalPoints} />
          </div>
        </div>
      ))}
    </>
  );
};

export default MatchupList;
