import React from 'react';
import { format } from 'date-fns';

interface ListModalProps {
  data: Array<any>;
  onAdd: (value: any) => void;
  onClose: (value: boolean) => void;
}

const ListModal: React.FC<ListModalProps> = ({ data, onAdd, onClose }) => {
  return (
    <div className="w-screen h-screen fixed top-0 left-0 z-50 flex flex-col items-center justify-center bg-modal-black px-10">
      <div className="w-[1000px] h-[400px] min-h-[400px] bg-white shadow-lg flex flex-col justify-between">
        <div className="overflow-x-auto">
          <table className="w-full table-auto">
            <thead className="text-xs tracking-wide text-left bg-gray-100 border-b sticky top-0">
              <tr>
                <th className="p-2 text-left whitespace-nowrap">Name</th>
                <th className="p-2 text-center whitespace-nowrap">Status</th>
                <th className="p-2 text-center whitespace-nowrap">Teams count</th>
                <th className="p-2 text-center whitespace-nowrap">Format</th>
                <th className="p-2 text-center whitespace-nowrap">Team limit</th>
                <th className="p-2 text-right whitespace-nowrap">Start date</th>
                <th className="p-2 text-right whitespace-nowrap">End date</th>
                <th className="p-2 text-right whitespace-nowrap">End (h)</th>
                <th className="p-2 text-right whitespace-nowrap">Timezone</th>
                <th className="p-2 text-right whitespace-nowrap"></th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-300">
              {data && data.length > 0 ? (
                data.map(league => (
                  <tr className={league.teamLimit === league.numberOfTeamsApplied ? 'bg-gray-300 opacity-50' : ''} key={league._id}>
                    <td className="p-2 text-left">{league.name}</td>
                    <td className="p-2 text-center whitespace-nowrap">{league.status}</td>
                    <td className="p-2 text-center whitespace-nowrap">{league.numberOfTeamsApplied}</td>
                    <td className="p-2 text-center whitespace-nowrap">{league.format}</td>
                    <td className="p-2 text-center whitespace-nowrap">{league.teamLimit}</td>
                    <td className="p-2 text-right whitespace-nowrap">{format(new Date(league.startDate), 'MM/dd/yyyy')}</td>
                    <td className="p-2 text-right whitespace-nowrap">{format(new Date(league.endDate), 'MM/dd/yyyy')}</td>
                    <td className="p-2 text-right whitespace-nowrap">{league.endHour}</td>
                    <td className="p-2 text-right whitespace-nowrap">{league.timezone}</td>
                    <td className="p-2 flex justify-end">
                      <button
                        disabled={league.teamLimit === league.numberOfTeamsApplied}
                        type="button"
                        className={
                          league.teamLimit === league.numberOfTeamsApplied
                            ? 'pointer-events-none p-2 text-xs text-black whitespace-nowrap no-underline bg-gray-600 rounded-md'
                            : 'p-2 text-xs text-white whitespace-nowrap no-underline bg-blue-600 rounded-md hover:bg-blue-500 hover:text-white'
                        }
                        onClick={() => onAdd(league)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          className="w-4 h-4">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="p-8 text-center text-gray-400" colSpan={10}>
                    No available leagues with status draft
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="border-t p-2 flex justify-end">
          <button
            type="button"
            className="w-20 flex justify-center p-2 text-xs text-white whitespace-nowrap no-underline bg-red-600 rounded-md hover:bg-red-500 hover:text-white"
            onClick={() => onClose(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ListModal;
