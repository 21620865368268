import { SessionService } from './SessionService';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Sign in with email and password
 * @param {string} email
 * @param {string} password
 */
const signIn = async (email: string, password: string) => {
  try {
    const response = await fetch(`${API_URL}/signin`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: UserService.js ~ signIn ~ error', error);
    return { status: 500 };
  }
};

const getAllUsers = async (skip: number, limit: number) => {
  try {
    const response = await fetch(`${API_URL}/user?${new URLSearchParams({ skip: skip.toString(), limit: limit.toString() })}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: UserService.js ~ getAllUsers ~ error', error);
    return { status: 500 };
  }
};

const getUserEdit = async (id: string) => {
  try {
    const response = await fetch(`${API_URL}/user/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: UserService.js ~ getUserEdit ~ error', error);
    return { status: 500 };
  }
};

const exportUsers = async () => {
  try {
    const response = await fetch(`${API_URL}/user/export`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.blob();
  } catch (error) {
    console.log('🚀 ~ file: UserService.js ~ exportUsers ~ error', error);
    return { status: 500 };
  }
};

export const UserService = {
  signIn,
  getAllUsers,
  getUserEdit,
  exportUsers,
};
