import React from 'react';

interface QuestionModalProps {
  text: string;
  onChange: (value: boolean) => void;
}

const QuestionModal: React.FC<QuestionModalProps> = ({ text, onChange }) => {
  return (
    <div className="w-screen h-screen fixed top-0 left-0 z-50 flex flex-col items-center justify-center bg-modal-black px-10">
      <div className="w-[400px] bg-white shadow-lg">
        <div className="p-4">
          <h1 className="text-md font-semibold">{text}</h1>
        </div>
        <div className="flex justify-end space-x-2 border-t p-2">
          <button
            type="button"
            className="w-20 flex justify-center p-2 text-xs text-white whitespace-nowrap no-underline bg-green-600 rounded-md hover:bg-green-500 hover:text-white"
            onClick={() => onChange(true)}>
            Yes
          </button>

          <button
            type="button"
            className="w-20 flex justify-center p-2 text-xs text-white whitespace-nowrap no-underline bg-red-600 rounded-md hover:bg-red-500 hover:text-white"
            onClick={() => onChange(false)}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionModal;
