import { SessionService } from './SessionService';

const API_URL = process.env.REACT_APP_API_URL;

const getAllMatchups = async (seasonId: string, leagueId: string) => {
  try {
    const response = await fetch(`${API_URL}/season/${seasonId}/league/${leagueId}/matchup`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: MatchupService.js ~ getAllMatchups ~ error', error);
    return { status: 500 };
  }
};

const addMatchup = async (seasonId: string, leagueId: string, teams: any, seasonWeek: number) => {
  try {
    const data = {
      teams,
      seasonWeek,
    };

    const response = await fetch(`${API_URL}/season/${seasonId}/league/${leagueId}/matchup`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: MatchupService.js ~ addMatchup ~ error', error);
    return { status: 500 };
  }
};

const deleteMatchup = async (seasonId: string, leagueId: string, matchupId: string) => {
  try {
    const data = {
      matchupId,
    };

    const response = await fetch(`${API_URL}/season/${seasonId}/league/${leagueId}/matchup/${matchupId}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: MatchupService.js ~ deleteMatchup ~ error', error);
    return { status: 500 };
  }
};

const getTeamStandings = async (seasonId: string, leagueId: string) => {
  try {
    const response = await fetch(`${API_URL}/season/${seasonId}/league/${leagueId}/standings`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: MatchupService.js ~ getTeamStandings ~ error', error);
    return { status: 500 };
  }
};

const getSoloMatchupStats = async () => {
  try {
    const response = await fetch(`${API_URL}/solo`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: MatchupService.js ~ getSoloMatchupStats ~ error', error);
    return { status: 500 };
  }
};

export const MatchupsService = {
  getAllMatchups,
  addMatchup,
  deleteMatchup,
  getTeamStandings,
  getSoloMatchupStats
};
