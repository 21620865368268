import React, { useState, useEffect, useRef } from 'react';
import { UploadService } from '../services';

interface UploadPhotoProps {
  initPhoto?: string;
  onSubmit: (photo: string) => void;
}

const UploadPhoto: React.FC<UploadPhotoProps> = ({ initPhoto, onSubmit }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [photo, setPhoto] = useState('');
  const photoInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (initPhoto) {
      setPhoto(() => initPhoto);
    }
  }, [initPhoto]);

  const handleOpenFileDialog = () => {
    if (photoInput.current) {
      photoInput.current.click();
    }
  };

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file: File = e.target.files[0];
    setIsUploading(() => true);
    const uploadedImage = await UploadService.upload(file);

    if (uploadedImage) {
      setIsUploading(() => false);
      setPhoto(() => uploadedImage.results);
      onSubmit(uploadedImage.results);
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center pb-4 text-xs text-center cursor-pointer"
      onClick={() => handleOpenFileDialog()}>
      <div className="flex items-center justify-center object-cover w-32 h-32 mb-1 bg-gray-600 border-2 border-gray-300 rounded-full overflow-clip hover:border-black">
        {!photo ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-10 h-10 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        ) : (
          <img className="object-cover w-full h-full mx-auto" src={photo} alt="Upload Photo" />
        )}
        <input ref={photoInput} type="file" className="hidden" onChange={e => handleUpload(e)} />
      </div>
      {isUploading ? (
        <span className="flex px-2 py-1 text-white bg-black rounded-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4 mr-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
          Uploading...
        </span>
      ) : (
        <span className="flex px-2 py-1 text-white bg-black rounded-md">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4 mr-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
          Change photo
        </span>
      )}
    </div>
  );
};

export default UploadPhoto;
