import React from 'react';

const NotFound = () => (
  <div className="flex flex-col items-center justify-center h-screen p-8 align-middle">
    <h1 className="mb-4 text-4xl font-semibold">404</h1>
    <h2 className="text">The page you were looking for doesn't exist.</h2>
    <h2 className="text">You may have mistyped the address or the page may have moved.</h2>
  </div>
);

export default NotFound;
