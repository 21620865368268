import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { MultiTabSwitch, Sidebar } from 'src/components';
import { SeasonsService, SessionService } from 'src/services';
import { MatchupsService } from 'src/services/MatchupsService';

const StatsDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [statsSolo, setStatsSolo] = useState([]);
  const [statsSelfStart, setStatsSelfStart] = useState([]);
  const [statsType, setStatsType] = useState('Solo');

  useEffect(() => {
    const storedStatsType = sessionStorage.getItem('statsType');

    if (storedStatsType) {
      setStatsType(() => storedStatsType);

      if (storedStatsType === 'Self-Start') {
        getPrivateSeasonsStats();
      } else {
        getSoloMatchupStats();
      }
    } else {
      getSoloMatchupStats();
    }
  }, []);

  const getSoloMatchupStats = async () => {
    setIsLoading(() => true);
    const data = await MatchupsService.getSoloMatchupStats();

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data && data.results) {
      setStatsSolo(() => data.results);
    }
    setIsLoading(() => false);
  };

  const getPrivateSeasonsStats = async () => {
    setIsLoading(() => true);
    const data = await SeasonsService.getPrivateSeasonsStats();

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data && data.results) {
      setStatsSelfStart(() => data.results);
    }
    setIsLoading(() => false);
  };

  const handleStats = (value: string) => {
    setStatsType(() => value);
    sessionStorage.setItem('statsType', value);
    value === 'Self-Start' ? getPrivateSeasonsStats() : getSoloMatchupStats();
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <main className="py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <div className="flex items-center mt-2 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="w-6 h-6 mr-2">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"
                  />
                </svg>
                <h1 className="mr-4 text-xl font-semibold">Statistics</h1>
              </div>

              <div className="w-[400px] mb-4">
                <MultiTabSwitch
                  options={[
                    { key: '1 vs. 1', value: 'Solo' },
                    { key: 'Self-Start Private', value: 'Self-Start' },
                  ]}
                  optionKey="key"
                  optionValue="value"
                  value={statsType}
                  onChange={value => handleStats(value)}
                />
              </div>

              {statsType === 'Solo' && (
                <table className="w-full table-auto">
                  <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                    <tr>
                      <th className="p-2 text-left whitespace-nowrap">Month/Year</th>
                      <th className="p-2 text-left whitespace-nowrap">Initiated</th>
                      <th className="p-2 text-center whitespace-nowrap">Accepted</th>
                      <th className="p-2 text-center whitespace-nowrap">In Progress</th>
                      <th className="p-2 text-center whitespace-nowrap">Completed</th>
                      <th className="p-2 text-center whitespace-nowrap">Rejected</th>
                      <th className="p-2 text-right whitespace-nowrap">Cancelled</th>
                      <th className="p-2 text-right whitespace-nowrap">Expired</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-300">
                    {isLoading && (
                      <tr>
                        <td className="text-center text-gray-400" colSpan={8}>
                          <Skeleton height={50} count={20} />
                        </td>
                      </tr>
                    )}
                    {!isLoading && statsSolo.length > 0 ? (
                      statsSolo.map((item: any, index: number) => (
                        <tr key={index}>
                          <td className="p-2 text-left whitespace-nowrap">
                            {item._id.month}/{item._id.year}
                          </td>
                          <td className="p-2 text-left whitespace-nowrap">{item.initiatedNumber}</td>
                          <td className="p-2 text-center whitespace-nowrap">{item.acceptedNumber}</td>
                          <td className="p-2 text-center whitespace-nowrap">{item.inProgressNumber}</td>
                          <td className="p-2 text-center whitespace-nowrap">{item.completedNumber}</td>
                          <td className="p-2 text-center whitespace-nowrap">{item.rejectedNumber}</td>
                          <td className="p-2 text-right whitespace-nowrap">{item.cancelledNumber}</td>
                          <td className="p-2 text-right whitespace-nowrap">{item.expiredNumber}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-8 text-center text-gray-400" colSpan={8}>
                          No stats in database
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}

              {statsType === 'Self-Start' && (
                <table className="w-full table-auto">
                  <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                    <tr>
                      <th className="p-2 text-left whitespace-nowrap">Month/Year</th>
                      <th className="p-2 text-center whitespace-nowrap">Initiated</th>
                      <th className="p-2 text-center whitespace-nowrap">Season Pending</th>
                      <th className="p-2 text-center whitespace-nowrap">Season Active</th>
                      <th className="p-2 text-right whitespace-nowrap">Season Completed</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-300">
                    {isLoading && (
                      <tr>
                        <td className="text-center text-gray-400" colSpan={5}>
                          <Skeleton height={50} count={20} />
                        </td>
                      </tr>
                    )}
                    {!isLoading && statsSelfStart.length > 0 ? (
                      statsSelfStart.map((item: any, index: number) => (
                        <tr key={index}>
                          <td className="p-2 text-left whitespace-nowrap">
                            {item._id.month}/{item._id.year}
                          </td>
                          <td className="p-2 text-center whitespace-nowrap">{item.initiatedNumber}</td>
                          <td className="p-2 text-center whitespace-nowrap">{item.pendingNumber}</td>
                          <td className="p-2 text-center whitespace-nowrap">{item.activeNumber}</td>
                          <td className="p-2 text-right whitespace-nowrap">{item.completedNumber}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-8 text-center text-gray-400" colSpan={5}>
                          No stats in database
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </main>
        </main>
      </div>
    </div>
  );
};

export default StatsDetails;
