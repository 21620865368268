import React from 'react';
import { Utils } from 'src/utils/Utils';

interface MatchupItemProps {
  matchup: Array<any>;
}

const MatchupItem: React.FC<MatchupItemProps> = ({ matchup }) => {
  const isGreaterThan = (x: number, y: number) => {
    return x > y;
  };

  return (
    <div className="flex flex-row w-full h-full">
      <div className="w-full h-full py-[10px]">
        <div className="w-full flex flex-row items-center justify-between mb-[8px]">
          <div
            className={`flex flex-row items-center justify-center rounded-[24px] min-w-[58px] h-[28px] px-[16px] ${
              isGreaterThan(matchup[0]?.points, matchup[1]?.points) ? 'bg-black' : 'bg-white'
            }`}>
            <span className={`text-sm ${isGreaterThan(matchup[0]?.points, matchup[1]?.points) ? 'text-white' : 'text-black'}`}>
              {Utils.roundNumber(matchup[0]?.points)}
            </span>
          </div>
          <img src={matchup[0]?.team?.photo} alt="" className="w-[100px] h-[100px] object-cover rounded-full bg-black" />
        </div>

        <div className="flex flex-col items-end">
          <div className="font-bold text-sm mb-[6px]">{matchup[0]?.team?.name}</div>
          <div className="text-sm">{matchup[0]?.winLoss}</div>
        </div>
      </div>

      <div className="mx-[40px] bg-black">
        <span className="border-r border-black"></span>
      </div>

      <div className="w-full h-full py-[10px]">
        <div className="w-full flex flex-row items-center justify-between mb-[8px]">
          <img src={matchup[1]?.team?.photo} alt="" className="w-[100px] h-[100px] object-cover rounded-full bg-black" />
          <div
            className={`flex flex-row items-center justify-center rounded-[24px] min-w-[58px] h-[28px] px-[16px] ${
              isGreaterThan(matchup[1]?.points, matchup[0]?.points) ? 'bg-black' : 'bg-white'
            }`}>
            <span className={`text-sm ${isGreaterThan(matchup[1]?.points, matchup[0]?.points) ? 'text-white' : 'text-black'}`}>
              {Utils.roundNumber(matchup[1]?.points)}
            </span>
          </div>
        </div>

        <div className="w-full font-bold text-sm mb-[6px]">{matchup[1]?.team?.name}</div>
        <div className="text-sm">{matchup[1]?.winLoss}</div>
      </div>
    </div>
  );
};

export default MatchupItem;
