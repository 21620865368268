import React from 'react';
import { Utils } from 'src/utils/Utils';

interface BracketItemProps {
  bracket: Array<any>;
}

const BracketItem: React.FC<BracketItemProps> = ({ bracket }) => {
  return (
    <div className="bg-gray-100 rounded-md mb-[8px]">
      {bracket.map((item, index) => (
        <div
          key={item?.team?._id}
          className={`w-full flex flex-row items-center justify-between p-[16px] ${
            index + 1 < bracket.length ? 'border-b border-black' : 'border-none'
          } `}>
          <div className="flex flex-row items-center relative">
            {item?.place && <span className="absolute -top-3 -left-2 mr-[8px] text-xs">#{item?.place}</span>}
            <img src={item?.team?.photo} alt="" className="w-[100px] h-[100px] object-cover rounded-full bg-black mr-[16px]" />
            <span className="font-bold text-sm">{item?.team?.name}</span>
          </div>

          <div
            className={`flex flex-row items-center justify-center rounded-[24px] min-w-[58px] h-[28px] px-[16px] ${
              item?.isGreater ? 'bg-black' : 'bg-white'
            }`}>
            <span className={`text-sm ${item?.isGreater ? 'text-white' : 'text-black'}`}>{Utils.roundNumber(item?.points) || 0}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BracketItem;
