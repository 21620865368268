import { format } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { Utils } from 'src/utils/Utils';
import ArrowSwitch from '../ArrowSwitch';
import Skeleton from 'react-loading-skeleton';

interface LeaderboardTeamProps {
  leaderboard: Array<any>;
  periodType: string;
  isLoading?: boolean;
}

const LeaderboardTeam: React.FC<LeaderboardTeamProps> = ({ leaderboard, periodType, isLoading }) => {
  const [currentDay, setCurrentDay] = useState('');
  const [currentWeek, setCurrentWeek] = useState('');
  const [days, setDays] = useState<Array<any>>([]);
  const [weeks, setWeeks] = useState<Array<any>>([]);
  const [response, setResponse] = useState<Array<any>>([]);

  useMemo(() => {
    if (leaderboard && leaderboard.length > 0) {
      if (periodType === 'Day') {
        const storedTeamCurrentDay = localStorage.getItem('TeamCurrentDay');
        setCurrentDay(() => (storedTeamCurrentDay ? storedTeamCurrentDay : format(new Date(), 'EEEE')));
        setDays([]);
        leaderboard.map(item => setDays(days => [...days, { name: item._id.dayName, value: item._id.day }]));
        const index = leaderboard.findIndex(
          item => item._id.dayName === (storedTeamCurrentDay ? storedTeamCurrentDay : format(new Date(), 'EEEE'))
        );
        setResponse(() => leaderboard[index]?.data);
      }

      if (periodType === 'Week') {
        const storedTeamCurrentWeek = localStorage.getItem('TeamCurrentWeek');
        setCurrentWeek(() => (storedTeamCurrentWeek ? storedTeamCurrentWeek : leaderboard[leaderboard.length - 1]?._id?.weekRange));
        setWeeks([]);
        leaderboard.map(item =>
          setWeeks(weeks => [...weeks, { name: item._id.weekRange, value: item._id.weekRange, sub: format(new Date(), 'EEEE, MMMM dd') }])
        );
        const index = leaderboard.findIndex(
          item =>
            item._id.weekRange === (storedTeamCurrentWeek ? storedTeamCurrentWeek : leaderboard[leaderboard.length - 1]?._id?.weekRange)
        );
        setResponse(() => leaderboard[index]?.data);
      }

      if (periodType === 'Season') {
        setResponse(() => leaderboard);
      }
    }
  }, [leaderboard]);

  const handleDayFilter = async (value: string) => {
    localStorage.setItem('TeamCurrentDay', value);
    setCurrentDay(() => value);

    // setResponse([]);
    const index = leaderboard.findIndex(item => item._id.dayName === value);
    if (index !== -1) {
      setResponse(() => leaderboard[index]?.data);
    }
  };

  const handleWeekFilter = async (value: string) => {
    localStorage.setItem('TeamCurrentWeek', value);
    setCurrentWeek(() => value);

    // setResponse([]);
    const index = leaderboard.findIndex(item => item._id.weekRange === value);
    if (index !== -1) {
      setResponse(() => leaderboard[index]?.data);
    }
  };

  if (isLoading) {
    return <Skeleton height={50} borderRadius={0} count={20} />;
  }

  return (
    <>
      {periodType === 'Day' && (
        <div className="h-[50px]">
          <ArrowSwitch
            isDayFilter
            value={currentDay}
            options={days}
            optionKey="name"
            optionValue="value"
            onPrev={handleDayFilter}
            onNext={handleDayFilter}
          />
        </div>
      )}

      {periodType === 'Week' && (
        <div className="h-[50px]">
          <ArrowSwitch
            value={currentWeek}
            options={weeks}
            optionKey="name"
            optionValue="value"
            onPrev={handleWeekFilter}
            onNext={handleWeekFilter}
          />
        </div>
      )}

      <table className="w-full table-auto">
        <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
          <tr>
            <th className="p-2 text-left whitespace-nowrap"></th>
            <th className="p-2 text-left whitespace-nowrap"></th>
            <th className="p-2 text-left whitespace-nowrap">Team Name</th>
            <th className="p-2 text-left whitespace-nowrap">Won-Lost</th>
            <th className="p-2 text-right whitespace-nowrap">Total Points</th>
          </tr>
        </thead>
        <tbody className="text-sm divide-y divide-gray-300">
          {response && response.length > 0 ? (
            response.map((item: any, index: number) => (
              <tr key={item._id}>
                <td className="p-2 w-10 text-left whitespace-nowrap">{index + 1}</td>
                <td className="p-2 w-10 text-left whitespace-nowrap">
                  <img
                    alt={`${item.name}`}
                    src={item.photo}
                    className="object-cover max-w-max w-8 h-8 bg-black border border-gray-300 rounded-full overflow-clip"
                  />
                </td>
                <td className="p-2 text-left whitespace-nowrap">{item.name}</td>
                <td className="p-2 text-left whitespace-nowrap">
                  {item.won}-{item.lost}
                </td>
                <td className="p-2 text-right whitespace-nowrap">{Utils.roundNumber(item.totalPoints)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="p-8 text-center text-gray-400" colSpan={5}>
                No leaderboard in database
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default LeaderboardTeam;
