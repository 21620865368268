import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SessionService, SeasonsService } from '../../services';
import { Sidebar, Switch, FormInput } from '../../components';

const SeasonNew = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!name) {
      alert('Name is required');
      return;
    }

    const data = await SeasonsService.newSeason(name, startDate, isPrivate);

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data.results) {
      navigate('/seasons');
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <div className="flex items-center mt-2 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                  />
                </svg>
                <h1 className="text-xl font-semibold">New Season</h1>
              </div>

              <div>
                <form onSubmit={handleSubmit}>
                  <div className="flex flex-row flex-wrap gap-4">
                    <div className="form-section lg:w-1/3">
                      <h2 className="pb-2 mb-4 text-lg font-semibold border-b">Format</h2>
                      <FormInput label="Name" type="text" placeholder="e.g. Season 2022" required={true} onChange={text => setName(text)} />
                      <FormInput
                        label="Start date"
                        type="date"
                        placeholder={'e.g. ' + new Date().toISOString().split('T')[0]}
                        min={new Date().toISOString().split('T')[0]}
                        value={startDate}
                        required={true}
                        onChange={text => setStartDate(text)}
                      />
                      <Switch title="Is private?" value={isPrivate} onChange={selected => setIsPrivate(selected)} />
                    </div>
                  </div>

                  <button type="submit" className="btn min-w-[120px] mt-4">
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SeasonNew;
