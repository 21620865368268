import React, { useId } from 'react';

interface InvitationLinkModalProps {
  teamName: string;
  link: string;
  onCopy: (value: string) => void;
  onClose: (value: boolean) => void;
}

const InvitationLinkModal: React.FC<InvitationLinkModalProps> = ({ teamName, link, onCopy, onClose }) => {
  const id: string = useId();

  return (
    <div className="w-screen h-screen fixed top-0 left-0 z-50 flex flex-col items-center justify-center bg-modal-black px-10">
      <div className="w-[400px] bg-white shadow-lg">
        <div className="p-4">
          <div className="pb-1">
            <span className="text-sm font-bold">{teamName}</span>
          </div>
          <div className="relative w-full px-3 py-3 -space-y-px bg-white border border-gray-300 rounded-md shadow-sm">
            <textarea
              id={id}
              name={id}
              style={{ resize: 'none' }}
              className="w-full text-gray-900 placeholder-gray-500 appearance-none focus:outline-none focus:ring-gray-300 focus:border-black focus:z-10 sm:text-sm"
              placeholder="Invitation Link"
              value={link.match(/\bhttps?:\/\/\S+/gi)![0]}
              readOnly
              rows={2}
            />
          </div>
        </div>
        <div className="flex justify-end space-x-2 border-t p-2">
          <button
            type="button"
            className="w-20 flex justify-center p-2 text-xs text-white whitespace-nowrap no-underline bg-green-600 rounded-md hover:bg-green-500 hover:text-white"
            onClick={() => onCopy(link.match(/\bhttps?:\/\/\S+/gi)![0])}>
            Copy
          </button>

          <button
            type="button"
            className="w-20 flex justify-center p-2 text-xs text-white whitespace-nowrap no-underline bg-red-600 rounded-md hover:bg-red-500 hover:text-white"
            onClick={() => onClose(false)}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvitationLinkModal;
