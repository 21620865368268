import React from 'react';

interface EmptyStateProps {
  title: string;
  subtitle?: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({ title, subtitle }) => {
  return (
    <div className="flex flex-col items-center justify-center rounded-md bg-gray-100 h-[200px] p-[32px]">
      <span className="font-bold text-xs">{title}</span>
      {subtitle && <span className="text-xs">{subtitle}</span>}
    </div>
  );
};

export default EmptyState;
