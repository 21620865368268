import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { QuestionModal, Sidebar } from 'src/components';
import { LeaguesService } from 'src/services';

const DemoDetails = () => {
  const [league, setLeague] = useState<any>(null);
  const [password, setPassword] = useState<string>('');
  const [teams, setTeams] = useState<Array<any>>([]);
  const [team, setTeam] = useState<any>(null);
  const [isQuestionModal, setIsQuestionModal] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [usersEmptyState, setUsersEmptyState] = useState('Click on a team to see users');

  useEffect(() => {
    const getData = async () => {
      const data = await LeaguesService.getDemoLeague();

      if (data && data?.results) {
        setLeague(() => data.results?.league);
        setPassword(() => data.results?.password);
        setTeams(() => data.results?.teams);
      }
    };

    getData();
  }, []);

  const removeLeague = async () => {
    setIsRemoving(true);
    const data = await LeaguesService.removeDemoLeague();

    if (data) {
      setIsQuestionModal(false);
      setLeague(null);
      setTeams([]);
      setTeam(null);
      setIsRemoving(false);
    }
  };

  const handleQuestionModal = (value: boolean) => {
    value ? removeLeague() : setIsQuestionModal(false);
  };

  const showUsers = (team: any) => {
    setTeam(team);
    setUsersEmptyState(team?.users?.length > 0 ? 'Click on a team to see users' : 'No users');
  };

  if (isRemoving) {
    return (
      <div className="flex h-screen overflow-hidden justify-center items-center">
        <p>Removing demo league...</p>
      </div>
    );
  }

  return (
    <>
      <div className="flex h-screen overflow-hidden">
        <Sidebar />
        <div className="flex flex-col flex-1 w-0 overflow-hidden">
          <main className="relative flex-1 overflow-y-auto focus:outline-none">
            <main className="py-6">
              <div className="w-full px-4 sm:px-6 md:px-8">
                <div className="flex items-center mt-2 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-6 h-6 mr-2">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.25 9.75 16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"
                    />
                  </svg>

                  <h1 className="mr-4 text-xl font-semibold">Demo League</h1>

                  {!league && (
                    <Link
                      to={'/demo/new'}
                      className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4 mr-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}>
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                        />
                      </svg>
                      Create Demo League
                    </Link>
                  )}

                  {league && (
                    <button
                      className="flex p-2 text-xs text-white no-underline bg-red-600 rounded-md hover:bg-red-500 hover:text-white"
                      onClick={() => setIsQuestionModal(true)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-4 h-4 mr-2">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                      Remove League
                    </button>
                  )}
                </div>

                {league && (
                  <div className="flex flex-wrap flex-col w-[800px] h-[400px] p-8 bg-gray-100 mb-4">
                    <div className="mb-4">
                      <p className="font-bold text-xs">Name</p>
                      <p className="text-sm">{league?.name}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Description</p>
                      <p className="text-sm">{league?.description}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Type</p>
                      <p className="text-sm">{league?.playoffGenerated ? 'Playoff' : 'Regular'}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Status</p>
                      <p className="text-sm">{league?.status}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Team count</p>
                      <p className="text-sm">{league?.teams?.length}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Format</p>
                      <p className="text-sm">{league?.format}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Team limit</p>
                      <p className="text-sm">{league?.teamLimit}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Regular weeks</p>
                      <p className="text-sm">{league?.regularWeeks?.numberOfWeeks} weeks</p>
                      <p className="text-sm">{league?.regularWeeks?.weekLength} days/week</p>
                      <p className="text-sm">{league?.regularWeeks?.flexibleOffDaysNumber} flexible off-days/week</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Playoff weeks</p>
                      <p className="text-sm">bracket size is {league?.playoffWeeks?.bracketSize}</p>
                      <p className="text-sm">{league?.playoffWeeks?.numberOfWeeks} weeks</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">End (h)</p>
                      <p className="text-sm">{league?.endHour}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Timezone</p>
                      <p className="text-sm">{league?.timezone}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Daily max</p>
                      <p className="text-sm">{league?.dailyMax}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Team bonus</p>
                      <p className="text-sm">{league?.teamBonus}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Bonuses</p>
                      {league?.bonuses?.alcohol && <p className="text-sm">Alcohol</p>}
                      {league?.bonuses?.junkFood && <p className="text-sm">Junk Food</p>}
                      {league?.bonuses?.water && <p className="text-sm">Water</p>}
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Created at</p>
                      <p className="text-sm">{league?.createdAt && format(new Date(league.createdAt), 'MM/dd/yyyy')}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Updated at</p>
                      <p className="text-sm">{league?.updatedAt && format(new Date(league.updatedAt), 'MM/dd/yyyy')}</p>
                    </div>
                    <div className="mb-4">
                      <p className="font-bold text-xs">Demo accounts password</p>
                      <p className="font-bold text-sm text-red-600">{password}</p>
                    </div>
                    {league?.playoffGenerated && (
                      <div className="mb-4 bg-green-600 p-2">
                        <p className="font-bold text-xs text-white">Team in finals</p>
                      </div>
                    )}
                  </div>
                )}

                {league && teams.length > 0 && (
                  <div className="flex space-x-12">
                    <div>
                      <h1 className="font-bold mb-2">Teams</h1>
                      <table className="w-[300px] table-auto">
                        <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                          <tr>
                            <th className="p-2 text-left whitespace-nowrap">Team name</th>
                            <th className="p-2 text-right whitespace-nowrap">Size</th>
                          </tr>
                        </thead>
                        <tbody className="text-sm divide-y divide-gray-300">
                          {teams.length > 0 ? (
                            teams.map(team => (
                              <tr
                                key={team?._id}
                                className={`cursor-pointer ${
                                  league?.playoffGenerated && team?.inFinals ? 'bg-green-600 text-white' : 'bg-white text-black'
                                }`}
                                onClick={() => showUsers(team)}>
                                <td className="p-2 text-left whitespace-nowrap">{team?.name}</td>
                                <td className="p-2 text-right whitespace-nowrap">{team?.users?.length}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="p-8 text-center text-gray-400" colSpan={2}>
                                No teams
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div>
                      <h1 className="font-bold mb-2">
                        Users (Team:{' '}
                        <span className={league?.playoffGenerated && team?.inFinals ? 'text-green-600' : 'text-black'}>{`${
                          team ? team?.name : ''
                        }`}</span>
                        )
                      </h1>
                      <table className="w-[600px] table-auto">
                        <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                          <tr>
                            <th className="p-2 text-left whitespace-nowrap">User name</th>
                            <th className="p-2 text-right whitespace-nowrap">Email</th>
                          </tr>
                        </thead>
                        <tbody className="text-sm divide-y divide-gray-300">
                          {team?.users.length > 0 ? (
                            team?.users.map((user: any) => (
                              <tr key={user?._id}>
                                <td className="p-2 text-left whitespace-nowrap">
                                  {user?.firstName} {user?.lastName}
                                </td>
                                <td className="p-2 text-right whitespace-nowrap">{user?.email}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td className="p-8 text-center text-gray-400" colSpan={3}>
                                {usersEmptyState}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </main>
          </main>
        </div>
      </div>
      {isQuestionModal && (
        <QuestionModal text={`Are you sure you want to remove this demo league?`} onChange={value => handleQuestionModal(value)} />
      )}
    </>
  );
};

export default DemoDetails;
