import React from 'react';
import BracketItem from './BracketItem';
import EmptyState from '../EmptyState';
import Skeleton from 'react-loading-skeleton';

interface BracketListProps {
  brackets: Array<any>;
  isLoading?: boolean;
}

const BracketList: React.FC<BracketListProps> = ({ brackets, isLoading }) => {
  if (isLoading) {
    return <Skeleton height={200} borderRadius={6} count={3} />;
  }

  if (brackets.length === 0 || brackets.every(item => item?.totalPoints.includes(null))) {
    return <EmptyState title="No brackets" />;
  }

  return (
    <>
      {brackets.map(item => (
        <div key={item?._id}>
          <BracketItem bracket={item?.totalPoints} />
        </div>
      ))}
    </>
  );
};

export default BracketList;
