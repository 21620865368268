import React, { useId } from 'react';

interface FormTextAreaProps {
  label: string;
  placeholder: string;
  required?: boolean;
  value?: string;
  autoComplete?: string;
  onChange: (value: string) => void;
}

const FormTextArea: React.FC<FormTextAreaProps> = ({ label, placeholder, required, value, autoComplete, onChange }) => {
  const id: string = useId();
  const isRequired: boolean = required ? true : false;

  return (
    <div className="relative w-full px-3 py-3 -space-y-px bg-white border border-gray-300 rounded-md shadow-sm">
      <label htmlFor={id} className="block text-xs font-bold">
        {label} <span className="font-normal text-gray-600">{required ? '*' : '(optional)'}</span>
      </label>
      <textarea
        id={id}
        name={id}
        className="w-full h-16 pt-2 text-gray-900 placeholder-gray-500 appearance-none focus:outline-none focus:ring-gray-300 focus:border-black focus:z-10 sm:text-sm"
        placeholder={placeholder}
        value={value}
        autoComplete={autoComplete}
        onChange={e => onChange(e.target.value)}
        required={isRequired}
      />
    </div>
  );
};

export default FormTextArea;
