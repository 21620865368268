import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Sidebar } from '../../components';
import MatchupPlayoffsBrackets from '../../components/MatchupPlayoffsBrackets';
import { LeaguesService, SessionService } from '../../services';

const MatchupPlayoffsDetails = () => {
  const { seasonId, leagueId } = useParams();
  const [playoffsBrackets, setPlayoffsBrackets] = useState<Array<any>>([]);

  useMemo(() => {
    const getData = async () => {
      const data = await LeaguesService.getLeague(seasonId!, leagueId!);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.results) {
        let playoffs: any = [
          {
            _id: Math.floor(Math.random() * 1000000000),
            title: 'Quarter-Finals',
            brackets: [],
            playoffSize: 8,
          },
          {
            _id: Math.floor(Math.random() * 1000000000),
            title: 'Semi-Finals',
            brackets: [],
            playoffSize: 4,
          },
          {
            _id: Math.floor(Math.random() * 1000000000),
            title: 'Finals',
            brackets: [],
            playoffSize: 2,
          },
        ];

        for (let item of data.results.playoffBrackets) {
          switch (item.legType) {
            case 'Quarter-Finals':
              playoffs[0].brackets.push(item);
              break;
            case 'Semi-Finals':
              playoffs[1].brackets.push(item);
              break;
            default:
              playoffs[2].brackets.push(item);
              break;
          }
        }

        setPlayoffsBrackets(() => playoffs);
      }
    };

    getData();
  }, []);

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <div className="flex items-center mt-2 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
                  />
                </svg>
                <h1 className="text-xl font-semibold">Playoffs</h1>
              </div>

              <div className="grid grid-cols-1 gap-4">
                <div className="flex space-x-4">
                  <MatchupPlayoffsBrackets data={playoffsBrackets} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default MatchupPlayoffsDetails;
