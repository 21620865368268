import React, { useState, useEffect } from 'react';

const DemoLoading = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadingMessages = [
    // 'Loading...',
    // 'Please wait, it takes some time...',
    // 'Do not exit or reload page...',
    // 'Fetching data...',
    // 'Season creation is in progress...',
    // 'League creation is in progress...',
    // 'Almost there...',
    // 'Teams are created...',
    // 'Users are being created...',
    // 'Matchup creation is in progress...',
    // 'Workouts are being created...',
    // 'Statistics are generated...',
    'Loading...',
    'Please wait, it takes some time...',
    'Do not exit or reload page...',
    'Generating data...',
    'Almost there...',
  ];
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessageIndex(prevIndex => (prevIndex + 1) % loadingMessages.length);
    }, 8000);

    return () => clearInterval(interval);
  }, [loadingMessages]);

  return (
    <div className={'flex h-screen overflow-hidden justify-center items-center'}>
      <p>{loadingMessages[currentMessageIndex]}</p>
    </div>
  );
};

export default DemoLoading;
