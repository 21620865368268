import React, { useEffect, useState } from 'react';
import { Utils } from '../utils/Utils';
import { SessionService, TeamService } from 'src/services';

// TDOD: missing interfaces Standing, Team

interface MatchupWeekStandingsProps {
  data: any;
  league: any;
  onMoveTo: (value: { _id: string; name: string; photo: string }) => void;
  onOpenLink: (value: string) => void;
  onRemoveTeam: (value: { _id: string; name: string; photo: string }) => void;
}

const MatchupWeekStandings: React.FC<MatchupWeekStandingsProps> = ({ data, league, onMoveTo, onOpenLink, onRemoveTeam }) => {
  const [standings, setStandings] = useState<any[]>([]);

  useEffect(() => {
    setStandings(data);
  }, [data]);

  const moveTo = (team: { _id: string; name: string; photo: string }) => {
    onMoveTo(team);
  };

  const deleteTeam = (team: { _id: string; name: string; photo: string }) => {
    onRemoveTeam(team);
  };

  const editTeamName = (index: number) => {
    const data = [...standings];
    data[index].team.isEdit = true;
    setStandings(() => data);
  };

  const onChangeTeamName = (index: number, teamName: string) => {
    const data = [...standings];
    data[index].team.name = teamName;
    setStandings(() => data);
  };

  const saveTeamName = async (index: number, teamId: string, teamName: string) => {
    const data = await TeamService.changeTeamName(teamId, teamName);

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data) {
      const data = [...standings];
      data[index].team.isEdit = false;
      data[index].team.name = teamName;
      setStandings(() => data);
    }
  };

  return (
    <div className="w-[800px]">
      <table className="w-full table-auto">
        <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
          <tr>
            <th className="p-2 text-left whitespace-nowrap"></th>
            <th className="p-2 text-left whitespace-nowrap">Team Name</th>
            {league?.status === 'Draft' && <th className="p-2"></th>}
            <th className="p-2 text-center whitespace-nowrap"></th>
            <th className="p-2 text-center whitespace-nowrap">Members</th>
            <th className="p-2 text-center whitespace-nowrap">Win-Loss</th>
            <th className="p-2 text-right whitespace-nowrap">Points</th>
            {league?.status === 'Draft' && <th className="p-2"></th>}
          </tr>
        </thead>
        <tbody className="text-sm divide-y divide-gray-300">
          {standings.length > 0 ? (
            standings.map((standing: any, index: number) => (
              <tr key={index}>
                <td className="p-2 w-10 text-left whitespace-nowrap">
                  <img
                    alt={standing.team?.name}
                    src={standing.team?.photo}
                    className="object-cover max-w-max w-8 h-8 bg-black border border-gray-300 rounded-full overflow-clip"
                  />
                </td>
                {!standing.team?.isEdit && (
                  <td className="p-2 text-left min-w-[180px]">
                    <button type="button" className="text-blue-600 text-left underline" onClick={() => editTeamName(index)}>
                      {standing.team?.name}
                    </button>
                  </td>
                )}
                {standing.team?.isEdit && (
                  <td className="flex space-x-2 p-2 text-left max-w-[180px]">
                    <button
                      type="submit"
                      className={`flex p-2 bg-green-600 rounded-md text-xs text-white no-underline hover:bg-green-500 hover:text-white
                      }`}
                      onClick={() => saveTeamName(index, standing.team?._id, standing.team?.name)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                      </svg>
                    </button>
                    <div className="relative w-full px-2 py-1 bg-white border border-gray-300 rounded-md shadow-sm">
                      <input
                        id={'teamName' + index}
                        name={'teamName' + index}
                        type="text"
                        className="w-full text-gray-900 placeholder-gray-500 appearance-none focus:outline-none focus:fill-transparent focus:z-10 sm:text-sm"
                        value={standing.team?.name}
                        onChange={e => onChangeTeamName(index, e.target.value)}
                      />
                    </div>
                  </td>
                )}
                {league?.status === 'Draft' && (
                  <td className="p-2">
                    <button
                      type="button"
                      className="flex p-2 text-xs text-white whitespace-nowrap no-underline bg-gray-600 rounded-md hover:bg-gray-500 hover:text-white"
                      onClick={() => moveTo(standing.team)}>
                      Move to
                    </button>
                  </td>
                )}
                <td className="p-2">
                  <button
                    disabled={!standing.team?.invitationText}
                    type="button"
                    className="flex p-2 text-xs text-white whitespace-nowrap no-underline bg-blue-600 hover:bg-blue-500 rounded-md hover:text-white disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => onOpenLink(standing.team)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFF" className="w-4 h-4">
                      <path d="M12.232 4.232a2.5 2.5 0 013.536 3.536l-1.225 1.224a.75.75 0 001.061 1.06l1.224-1.224a4 4 0 00-5.656-5.656l-3 3a4 4 0 00.225 5.865.75.75 0 00.977-1.138 2.5 2.5 0 01-.142-3.667l3-3z" />
                      <path d="M11.603 7.963a.75.75 0 00-.977 1.138 2.5 2.5 0 01.142 3.667l-3 3a2.5 2.5 0 01-3.536-3.536l1.225-1.224a.75.75 0 00-1.061-1.06l-1.224 1.224a4 4 0 105.656 5.656l3-3a4 4 0 00-.225-5.865z" />
                    </svg>
                  </button>
                </td>
                <td className="p-2 text-center">{standing.users}</td>
                <td className="p-2 text-center">{`${standing.won}-${standing.lost}`}</td>
                <td className="p-2 text-right">{Utils.roundNumber(standing.totalPoints)}</td>
                {league?.status === 'Draft' && (
                  <td className="p-2">
                    <button
                      type="button"
                      className="flex p-2 text-xs text-white no-underline bg-red-600 rounded-md hover:bg-red-500 hover:text-white"
                      onClick={() => deleteTeam(standing.team)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td className="p-8 text-center text-gray-400" colSpan={6}>
                No teams in database
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MatchupWeekStandings;
