import React, { useId } from 'react';

interface FormInputProps {
  label: string;
  type?: string;
  placeholder: string;
  required?: boolean;
  value?: string;
  min?: number | string;
  max?: number;
  autoComplete?: string;
  onChange: (value: string) => void;
}

const FormInput: React.FC<FormInputProps> = ({ label, type, placeholder, required, value, min, max, autoComplete, onChange }) => {
  const id: string = useId();
  const isRequired: boolean = required ? true : false;

  return (
    <div className="relative w-full px-3 py-3 -space-y-px bg-white border border-gray-300 rounded-md shadow-sm">
      <label htmlFor={id} className="block text-xs font-bold">
        {label} <span className="font-normal text-gray-600">{required ? '*' : '(optional)'}</span>
      </label>
      <input
        id={id}
        name={id}
        type={type || 'text'}
        className="w-full text-gray-900 placeholder-gray-500 appearance-none focus:outline-none focus:fill-transparent focus:z-10 sm:text-sm"
        placeholder={placeholder}
        value={value}
        min={min}
        max={max}
        autoComplete={autoComplete}
        onChange={e => onChange(e.target.value)}
        required={isRequired}
      />
    </div>
  );
};

export default FormInput;
