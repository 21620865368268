import React from 'react';
import { useEffect, useState } from 'react';
import DropDown from './DropDown';

// TODO: missing interface Matchup, Team

interface MatchupItemProps {
  item: any;
  onSave: (item: any) => void; // TODO: item should be of type Matchup
  onDelete: (item: string) => void;
  teams: any;
  data: any;
}

const MatchupItem: React.FC<MatchupItemProps> = ({ item, onSave, onDelete, teams, data }) => {
  const [teamX, setTeamX] = useState('');
  const [teamY, setTeamY] = useState('');

  const isTeamExist = (teamId: string): Array<string> => {
    return data.some((matchup: { teams: Array<string> }) => matchup.teams.includes(teamId));
  };

  const handleTeamX = (teamId: string): void => {
    if (isTeamExist(teamId)) {
      alert('Team has already been choosen for this season week');
    } else if (teamId === teamY) {
      alert('Please choose different teams');
    } else {
      setTeamX(() => teamId);
    }
  };

  const handleTeamY = (teamId: string): void => {
    if (isTeamExist(teamId)) {
      alert('Team has already been choosen for this season week');
    } else if (teamId === teamX) {
      alert('Please choose different teams');
    } else {
      setTeamY(() => teamId);
    }
  };

  const saveMatchup = (item: { teams: Array<string> }) => {
    if (isTeamExist(teamX) || isTeamExist(teamY)) {
      alert('One or both teams have already been choosen for this season week');
    } else {
      item.teams = [teamX, teamY];
      onSave(item);
    }
  };

  const deleteMatchup = (item: { id: string }): void => {
    onDelete(item.id);
  };

  useEffect(() => {
    if (item.teams.length > 0) {
      setTeamX(() => item.teams[0]);
      setTeamY(() => item.teams[1]);
    } else {
      setTeamX(() => '');
      setTeamY(() => '');
    }
  }, [item]);

  return (
    <div className={`flex items-center space-x-2 mb-2`}>
      <DropDown
        label="Team"
        placeholder="Please choose a team"
        value={teamX}
        values={teams}
        required={true}
        onChange={e => handleTeamX(e)}
        disabled={item.teams.length > 0}
      />

      <p>vs.</p>

      <DropDown
        label="Team"
        placeholder="Please choose a team"
        value={teamY}
        values={teams}
        required={true}
        onChange={e => handleTeamY(e)}
        disabled={item.teams.length > 0}
      />

      <div className="flex space-x-2 w-fit">
        {item.teams.length === 0 && (
          <button
            type="submit"
            className={`flex p-2 bg-green-600 rounded-md text-xs text-white no-underline hover:bg-green-500 hover:text-white ${
              !teamX || !teamY ? 'disabled:opacity-60 disabled:pointer-events-none' : ''
            }`}
            disabled={!teamX || !teamY}
            onClick={() => saveMatchup(item)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
            </svg>
          </button>
        )}

        {(!item.status || item.status === 'Pending') && (
          <button
            type="button"
            className="flex p-2 text-xs text-white no-underline bg-red-600 rounded-md hover:bg-red-500 hover:text-white"
            onClick={() => deleteMatchup(item)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        )}

        {item.teams.length > 0 && <div className="w-8"></div>}
      </div>
    </div>
  );
};

export default MatchupItem;
