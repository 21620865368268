import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { SessionService, TeamService } from '../../services';
import { Sidebar } from '../../components';
import { Team } from '../../models';
import Pagination from 'src/components/Pagination';

const TeamsList = () => {
  const [teams, setTeams] = useState<Array<Team>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [skip, setSkip] = useState(0);
  // const [isInvitationLinkModal, setIsInvitationLinkModal] = useState(false);
  // const [invitationLink, setInvitationLink] = useState('');
  // const [teamName, setTeamName] = useState('');
  const pageLimit = 20;

  useEffect(() => {
    const getData = async () => {
      const data = await TeamService.getAllTeams(skip, pageLimit);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.results) {
        setTeams(() => data.results);
        setPageCount(() => data.count);
      }
    };

    getData();
  }, [currentPage, skip]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setSkip((page - 1) * pageLimit);
  };

  // const handleInvitationLinkModal = (value: any) => {
  //   if (value) {
  //     setTeamName(() => value.name);
  //     setInvitationLink(() => value.invitationText);
  //     setIsInvitationLinkModal(() => true);
  //   }
  // };

  // const handleCopyInvitationLink = (value: string) => {
  //   navigator.clipboard.writeText(value);
  //   setIsInvitationLinkModal(() => false);
  // };

  // const handleCloseInvitationModal = (value: boolean) => {
  //   setIsInvitationLinkModal(() => value);
  // };

  return (
    <>
      <div className="flex h-screen overflow-hidden">
        <Sidebar />
        <div className="flex flex-col flex-1 w-0 overflow-hidden">
          <main className="relative flex-1 overflow-y-auto focus:outline-none">
            <div className="py-6">
              <div className="w-full px-4 sm:px-6 md:px-8">
                <div className="flex items-center mt-2 mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                  <h1 className="text-xl font-semibold">Teams</h1>
                </div>

                <table className="w-full table-auto">
                  <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                    <tr>
                      <th className="p-2 text-left whitespace-nowrap"></th>
                      <th className="p-2 text-left whitespace-nowrap">Name</th>
                      {/* <th className="p-2 text-left whitespace-nowrap">Invite Link</th> */}
                      <th className="p-2 text-center whitespace-nowrap">Size</th>
                      <th className="p-2 text-center whitespace-nowrap">Members</th>
                      <th className="p-2 text-left whitespace-nowrap">Tagline</th>
                      <th className="p-2 text-center whitespace-nowrap">Daily average</th>
                      <th className="p-2 text-center whitespace-nowrap">Updated at</th>
                      <th className="p-2 text-center whitespace-nowrap">Created at</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-300">
                    {teams.length > 0 ? (
                      teams.map(team => (
                        <tr key={team._id}>
                          <td className="p-2 w-10 text-left whitespace-nowrap">
                            <img
                              alt={team.name}
                              src={team.photo}
                              className="object-cover max-w-max w-8 h-8 bg-black border border-gray-300 rounded-full overflow-clip"
                            />
                          </td>
                          <td className="p-2 text-left whitespace-nowrap">{team.name}</td>
                          {/* <td className="p-2 text-left whitespace-nowrap">
                            <button
                              disabled={!team.invitationText}
                              type="button"
                              className="flex p-2 text-xs text-white whitespace-nowrap no-underline bg-blue-600 hover:bg-blue-500 rounded-md hover:text-white disabled:opacity-50 disabled:pointer-events-none"
                              onClick={() => handleInvitationLinkModal(team)}>
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#FFF" className="w-4 h-4">
                                <path d="M12.232 4.232a2.5 2.5 0 013.536 3.536l-1.225 1.224a.75.75 0 001.061 1.06l1.224-1.224a4 4 0 00-5.656-5.656l-3 3a4 4 0 00.225 5.865.75.75 0 00.977-1.138 2.5 2.5 0 01-.142-3.667l3-3z" />
                                <path d="M11.603 7.963a.75.75 0 00-.977 1.138 2.5 2.5 0 01.142 3.667l-3 3a2.5 2.5 0 01-3.536-3.536l1.225-1.224a.75.75 0 00-1.061-1.06l-1.224 1.224a4 4 0 105.656 5.656l3-3a4 4 0 00-.225-5.865z" />
                              </svg>
                            </button>
                          </td> */}
                          <td className="p-2 text-center">{team.size}</td>
                          <td className="p-2 text-left">
                            <div className="flex flex-wrap">
                              {team.users.length > 0 ? (
                                team.users.map((user: any, index: any) => (
                                  // <img
                                  //   key={user._id}
                                  //   alt={`${user.firstName} ${user.LastName}`}
                                  //   src={user.photo || './../img/avatar.png'}
                                  //   className="object-cover w-8 h-8 -ml-3 bg-black border border-gray-300 rounded-full overflow-clip first:ml-0"
                                  // />
                                  <React.Fragment key={user._id}>
                                    <a href={`mailto:${user.email}`}>{user.email}</a>
                                    {index === team.users.length - 1 ? '' : ','}&nbsp;
                                  </React.Fragment>
                                ))
                              ) : (
                                <span className="text-xs text-gray-300">No members</span>
                              )}
                            </div>
                          </td>
                          <td className="p-2 text-left">
                            {team.tagline ? team.tagline : <span className="text-xs text-gray-300">No tagline</span>}
                          </td>
                          <td className="p-2 text-center">{team.dailyAverage ? team.dailyAverage.toFixed(2) : 0}</td>
                          <td className="p-2 text-center">{format(new Date(team.updatedAt), 'MM/dd/yyyy')}</td>
                          <td className="p-2 text-center">{format(new Date(team.createdAt), 'MM/dd/yyyy')}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-8 text-center text-gray-400" colSpan={8}>
                          No teams in database
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <Pagination
                  className="mt-8"
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={pageLimit}
                  onPageChange={(page: number) => handlePageChange(page)}
                />
              </div>
            </div>
          </main>
        </div>
      </div>
      {/* {isInvitationLinkModal && (
        <InvitationLinkModal
          teamName={teamName}
          link={invitationLink}
          onCopy={value => handleCopyInvitationLink(value)}
          onClose={value => handleCloseInvitationModal(value)}
        />
      )} */}
    </>
  );
};

export default TeamsList;
