import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { SessionService, UserService } from '../services';
import { authStore } from '../store/authStore';
import { FormInput } from '../components';

const SignIn = () => {
  const navigate = useNavigate();
  const setSession = useSetRecoilState(authStore);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (SessionService.isSessionValid()) {
      navigate('/teams');
    }
  }, [setSession]);

  const handleSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(() => true);
    const data = await UserService.signIn(email, password);
    setIsLoading(() => false);

    if (data.errorCode) {
      alert('Wrong credentials');
      return;
    }

    if (data.results) {
      SessionService.saveSession(data.results, data.token);
      setSession(() => data.results);
      navigate('/teams');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-full px-4 py-12 lg:h-screen lg:-mt-24">
      <div className="w-full max-w-sm space-y-8">
        <div>
          <img className="w-auto h-12 mx-auto rounded-md" src={`${process.env.PUBLIC_URL}/img/moove.png`} alt="Moove Admin" />
          <h2 className="mt-3 text-3xl font-extrabold text-center text-gray-900">Moove Together</h2>
        </div>
        <form className="mt-8 space-y-2" onSubmit={handleSignIn}>
          <FormInput
            label="Email address"
            type="email"
            placeholder="Your email address"
            autoComplete="email"
            required={true}
            onChange={text => setEmail(text)}
          />
          <FormInput
            label="Password"
            type="password"
            placeholder="Your password"
            autoComplete="current-password"
            required={true}
            onChange={text => setPassword(text)}
          />

          <button type="submit" className="w-full mx-auto btn">
            Sign in
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignIn;
