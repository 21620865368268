import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSwitch,
  BracketList,
  DropDown,
  LeaderboardIndividual,
  LeaderboardIndividualFilter,
  LeaderboardTeam,
  MatchupList,
  MultiTabSwitch,
  Sidebar,
} from 'src/components';
import { ReportService } from 'src/services';
import { filterStore } from 'src/store/filterStore';
import { useRecoilValue } from 'recoil';
import { format } from 'date-fns';

const LeagueDetails = () => {
  const navigate = useNavigate();
  const filters = useRecoilValue<any>(filterStore);
  const { seasonId, leagueId } = useParams();
  const [league, setLeague] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isMatchupsLoading, setIsMatchupsLoading] = useState(false);
  const [seasonName, setSeasonName] = useState('');
  const [leagues, setLeagues] = useState<Array<any>>([]);
  const [leagueStatus, setLeagueStatus] = useState('');
  const [matchups, setMatchups] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [leaderboardType, setLeaderboardType] = useState('Team');
  const [teamPeriodType, setTeamPeriodType] = useState('Season');
  const [soloPeriodType, setSoloPeriodType] = useState('Season');
  const [weeks, setWeeks] = useState<Array<any>>([]);
  const [currentWeek, setCurrentWeek] = useState('');
  const [currentWeekState, setCurrentWeekState] = useState('');
  const [seasonState, setSeasonState] = useState('');

  useEffect(() => {
    if (leagueId) {
      setLeague(() => leagueId);
    }

    const getData = async () => {
      const type = localStorage.getItem('LeaderboardType');
      if (type) {
        setLeaderboardType(() => type);
      }

      const teamPeriodType = localStorage.getItem('TeamPeriodType');
      if (teamPeriodType) {
        setTeamPeriodType(() => teamPeriodType);
      }

      const soloPeriodType = localStorage.getItem('SoloPeriodType');
      if (soloPeriodType) {
        setSoloPeriodType(() => soloPeriodType);
      }

      const data = await ReportService.getListOfLeagueNames(seasonId!);

      if (data.results) {
        setSeasonName(() => data.results.seasonName);
        setLeagues([]);
        data.results.leagues.map((league: any) => setLeagues(leagues => [...leagues, { key: league._id, value: league.name }]));
        const status = data.results.leagues.find((league: any) => league._id === leagueId).status;

        setLeagueStatus(() => status);

        getMatchups(leagueId!, Number(currentWeek));

        setIsLoading(() => true);
        if (!type || type === 'Team') {
          const data = await ReportService.getTeamLeagueLeaderboard(leagueId!, teamPeriodType || 'Season');
          setLeaderboard([]);
          if (data.results) {
            setLeaderboard(() => data.results.leaderboard);
          }
        } else {
          if (!filters) {
            // const period = status === 'Completed' ? 9 : undefined;
            const data = await ReportService.getIndividualLeagueLeaderboard(
              leagueId!,
              undefined,
              undefined,
              undefined,
              soloPeriodType || 'Season'
            );
            setLeaderboard([]);
            if (data.results) {
              setLeaderboard(() => data.results);
            }
          }
        }
        setIsLoading(() => false);
      }
    };

    getData();
  }, [leagueId]);

  useEffect(() => {
    if (filters) {
      const getData = async () => {
        setIsLoading(() => true);
        // const period = leagueStatus === 'Completed' ? 9 : filters?.day;
        const data = await ReportService.getIndividualLeagueLeaderboard(
          leagueId!,
          filters?.category,
          filters?.activity,
          undefined,
          soloPeriodType || 'Season'
        );
        // setLeaderboard([]);
        if (data.results) {
          setLeaderboard(() => data.results);
        }
        setIsLoading(() => false);
      };

      getData();
    }
  }, [filters]);

  const getMatchups = async (leagueId: string, week: number) => {
    setIsMatchupsLoading(() => true);
    const data = await ReportService.getListOfMatchupsPerWeek(leagueId, week);
    if (data.results) {
      setWeeks([]);
      data.results.league.seasonWeeks.map((item: any) =>
        setWeeks(weeks => [
          ...weeks,
          {
            key: item.name,
            value: item.weekNumber,
            sub: `${format(new Date(item.startDate), 'dd MMM yyyy')} - ${format(new Date(item.endDate), 'dd MMM yyyy')}`,
          },
        ])
      );

      data.results?.matchups.map((matchup: any) => {
        if (matchup.totalPoints[0]) {
          matchup.totalPoints[0].isGreater = matchup?.totalPoints[0]?.points > matchup?.totalPoints[1]?.points ? true : false;
        }

        if (matchup.totalPoints[1]) {
          matchup.totalPoints[1].isGreater = matchup?.totalPoints[0]?.points < matchup?.totalPoints[1]?.points ? true : false;
        }
      });

      setMatchups(() => data.results?.matchups);
      setCurrentWeek(() => data.results?.currentWeek?.weekNumber);
      setCurrentWeekState(() => data.results?.currentWeek?.weekNumber);
      setSeasonState(() => data.results?.seasonState);
    }
    setIsMatchupsLoading(() => false);
  };

  const handleLeaderboard = async (type: string) => {
    localStorage.setItem('LeaderboardType', type);
    setLeaderboardType(() => type);

    setIsLoading(() => true);
    setLeaderboard([]);
    if (type === 'Team') {
      const teamPeriodType = localStorage.getItem('TeamPeriodType');
      const data = await ReportService.getTeamLeagueLeaderboard(leagueId!, teamPeriodType || 'Season');
      if (data.results) {
        setLeaderboard(() => data.results.leaderboard);
      }
    } else {
      // const period = leagueStatus === 'Completed' ? 9 : filters?.day;
      const soloPeriodType = localStorage.getItem('SoloPeriodType');
      const data = await ReportService.getIndividualLeagueLeaderboard(
        leagueId!,
        filters?.category,
        filters?.activity,
        undefined,
        soloPeriodType || 'Season'
      );
      if (data.results) {
        setLeaderboard(() => data.results);
      }
    }
    setIsLoading(() => false);
  };

  const handlePeriodType = async (type: string) => {
    if (leaderboardType === 'Team') {
      localStorage.setItem('TeamPeriodType', type);
      setTeamPeriodType(() => type);

      setIsLoading(() => true);
      setLeaderboard([]);
      const data = await ReportService.getTeamLeagueLeaderboard(leagueId!, type);
      if (data.results) {
        setLeaderboard(() => data.results.leaderboard);
      }
      setIsLoading(() => false);
    } else {
      localStorage.setItem('SoloPeriodType', type);
      setSoloPeriodType(() => type);

      setIsLoading(() => true);
      setLeaderboard([]);
      const data = await ReportService.getIndividualLeagueLeaderboard(leagueId!, filters?.category, filters?.activity, undefined, type);
      if (data.results) {
        setLeaderboard(() => data.results);
      }
      setIsLoading(() => false);
    }
  };

  const navigateTo = (leagueId: string) => {
    setLeague(() => leagueId);
    setCurrentWeek('');
    setCurrentWeekState('');
    navigate(`/seasons/${seasonId}/leagues/${leagueId}`);
  };

  const handleArrowFilter = async (value: string) => {
    setCurrentWeek(() => value);
    setIsMatchupsLoading(() => true);
    const data = await ReportService.getListOfMatchupsPerWeek(leagueId!, Number(value));
    if (data.results) {
      data.results?.matchups.map((matchup: any) => {
        if (matchup.totalPoints[0]) {
          matchup.totalPoints[0].isGreater = matchup?.totalPoints[0]?.points > matchup?.totalPoints[1]?.points ? true : false;
        }

        if (matchup.totalPoints[1]) {
          matchup.totalPoints[1].isGreater = matchup?.totalPoints[0]?.points < matchup?.totalPoints[1]?.points ? true : false;
        }
      });

      setMatchups(() => data.results?.matchups);
      setSeasonState(() => data.results?.seasonState);
    }
    setIsMatchupsLoading(() => false);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="space-y-16 py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <div className="flex items-center mt-2 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"
                  />
                </svg>
                <h1 className="mr-4 text-xl font-semibold">Season: {seasonName}</h1>
              </div>

              <div className="w-[300px]">
                <DropDown
                  label="Leagues"
                  placeholder="Please choose a league"
                  values={leagues}
                  value={league}
                  required
                  onChange={value => navigateTo(value)}
                />
              </div>
            </div>

            <div className="w-2/3 px-4 sm:px-6 md:px-8">
              <div className="max-w-[600px]">
                <div className="h-[80px]">
                  <ArrowSwitch
                    preventOnNext
                    value={Number(currentWeek)}
                    state={Number(currentWeekState)}
                    options={weeks}
                    optionKey="key"
                    optionValue="value"
                    optionSub="sub"
                    onPrev={handleArrowFilter}
                    onNext={handleArrowFilter}
                  />
                </div>

                {seasonState === 'Playoffs' && <BracketList brackets={matchups} isLoading={isMatchupsLoading} />}

                {seasonState === 'Regular Season' && <MatchupList matchups={matchups} isLoading={isMatchupsLoading} />}
              </div>
            </div>

            <div className="w-2/3 px-4 sm:px-6 md:px-8">
              <div className="w-[300px] mb-8">
                <MultiTabSwitch
                  options={[
                    { key: 'Individual', value: 'Individual' },
                    { key: 'Team', value: 'Team' },
                  ]}
                  optionKey="key"
                  optionValue="value"
                  value={leaderboardType}
                  onChange={value => handleLeaderboard(value)}
                  disabled={isLoading}
                />
              </div>
              {leaderboardType === 'Individual' && (
                <div className="mb-2">
                  <LeaderboardIndividualFilter leagueStatus={leagueStatus} />
                </div>
              )}
              <div className="mb-2">
                <MultiTabSwitch
                  options={[
                    { key: 'Day', value: 'Day' },
                    { key: 'Week', value: 'Week' },
                    { key: 'Season', value: 'Season' },
                  ]}
                  optionKey="key"
                  optionValue="value"
                  value={leaderboardType === 'Team' ? teamPeriodType : soloPeriodType}
                  onChange={value => handlePeriodType(value)}
                  disabled={isLoading}
                />
              </div>
              {leaderboardType === 'Individual' && (
                <LeaderboardIndividual
                  leaderboard={leaderboard}
                  periodType={soloPeriodType}
                  isLoading={isLoading}
                  unitFilter={filters?.unit}
                />
              )}
              {leaderboardType === 'Team' && (
                <LeaderboardTeam leaderboard={leaderboard} periodType={teamPeriodType} isLoading={isLoading} />
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default LeagueDetails;
