import { jwtDecode } from 'jwt-decode';
import { Admin } from '../models';

const API_URL = process.env.REACT_APP_API_URL;

const getSessionFromStorage = () => {
  try {
    const session = localStorage.getItem('MooveUserSession');
    return session ? JSON.parse(session) : null;
  } catch (error) {
    console.log('🚀 ~ file: SessionService.js ~ getSessionFromStorage ~ error', error);
  }
};

const saveSession = (user: Admin, token: string) => {
  try {
    if (user) {
      localStorage.setItem('MooveUser', JSON.stringify(user));
    }

    if (token) {
      localStorage.setItem('MooveUserSession', JSON.stringify(token));
    }
  } catch (error) {
    console.log('🚀 ~ file: SessionService.js ~ saveSession ~ error', error);
  }
};

const getProfile = () => {
  try {
    const user = localStorage.getItem('MooveUser');
    return user ? JSON.parse(user) : null;
  } catch (error) {
    console.log('🚀 ~ file: SessionService.js ~ getProfile ~ error', error);
  }
};

const clearSession = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.log('🚀 ~ file: SessionService.js ~ clearSession ~ error', error);
  }
};

/**
 * Check if current user session is valid
 * @returns {Promise<boolean>}
 */
const isSessionValid = () => {
  try {
    const token = getSessionFromStorage();

    if (token) {
      const decodedJWT: any = jwtDecode(token);
      const expirationEpoh = decodedJWT.exp;
      const currentEpoh = new Date().getTime() / 1000;

      if (currentEpoh > expirationEpoh) {
        clearSession();
        return false;
      } else {
        return true;
      }
    }
  } catch (error) {
    console.log('🚀 ~ file: SessionService.js ~ isSessionValid ~ error', error);
    return false;
  }
};

/**
 * Refresh auth token
 * @returns
 */
const refreshAuthToken = async () => {
  try {
    const token = await getSessionFromStorage();
    const response = await fetch(`${API_URL}/refresh-token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        token,
      }),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: SessionService.js ~ refreshAuthToken ~ error', error);
  }
};

export const SessionService = {
  getSessionFromStorage,
  getProfile,
  saveSession,
  clearSession,
  isSessionValid,
  refreshAuthToken,
};
