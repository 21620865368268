import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { SessionService, UserService } from '../../services';
import { Sidebar } from '../../components';
import { User } from 'src/models';
import Pagination from 'src/components/Pagination';
import { Utils } from 'src/utils/Utils';

const UsersList = () => {
  const [users, setUsers] = useState<Array<User>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const pageLimit = 20;

  useEffect(() => {
    const getData = async () => {
      const data = await UserService.getAllUsers(skip, pageLimit);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.results) {
        setUsers(() => data.results);
        setPageCount(() => data.count);
      }
    };

    getData();
  }, [currentPage, skip]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setSkip((page - 1) * pageLimit);
  };

  const exportUsersTo = async (format: string) => {
    const res = await UserService.exportUsers();

    if (res) {
      Utils.export(res as Blob, 'Users', format);
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <div className="flex items-center mt-2 mb-4">
                <div className="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mr-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                  <h1 className="text-xl font-semibold">Users</h1>
                </div>
                <div className="flex space-x-2 ml-8">
                  <button
                    className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white"
                    onClick={() => exportUsersTo('csv')}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-1">
                      <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                      <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                    </svg>
                    Download .csv
                  </button>
                  <button
                    className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white"
                    onClick={() => exportUsersTo('xlsx')}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-1">
                      <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                      <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                    </svg>
                    Download .xlsx
                  </button>
                </div>
              </div>

              <table className="w-full table-auto">
                <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                  <tr>
                    <th className="p-2 text-left whitespace-nowrap"></th>
                    <th className="p-2 text-left whitespace-nowrap">Name</th>
                    <th className="p-2 text-left whitespace-nowrap">Email</th>
                    <th className="p-2 text-left whitespace-nowrap">User ID</th>
                    <th className="p-2 text-center whitespace-nowrap">Date of Birth</th>
                    <th className="p-2 text-center whitespace-nowrap">Location</th>
                    <th className="p-2 text-center whitespace-nowrap">Registration Date</th>
                    <th className="p-2 text-center whitespace-nowrap">1v1 Created</th>
                    <th className="p-2 text-center whitespace-nowrap">1v1 Accepted</th>
                    <th className="p-2 text-center whitespace-nowrap">1v1 Started</th>
                    <th className="p-2 text-center whitespace-nowrap">1v1 Completed</th>
                    <th className="p-2 text-center whitespace-nowrap">Count of Seasons Participated In</th>
                    <th className="p-2 text-center whitespace-nowrap">Total points earned</th>
                    <th className="p-2 text-center whitespace-nowrap">Streak</th>
                    <th className="p-2 text-center whitespace-nowrap">Daily average</th>
                    <th className="p-2 text-center whitespace-nowrap">Notifications</th>
                    <th className="p-2 text-center whitespace-nowrap">Trophies count</th>
                    <th className="p-2 text-right whitespace-nowrap">Expire at</th>
                    <th className="p-2 text-right whitespace-nowrap">Updated at</th>
                    <th className="p-2 text-center whitespace-nowrap">Is active</th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-300">
                  {users.length > 0 ? (
                    users.map(user => (
                      <tr key={user._id}>
                        <td className="p-2 w-10 text-left whitespace-nowrap">
                          <img
                            alt={`${user.firstName} ${user.lastName}`}
                            src={user.photo}
                            className="object-cover max-w-max w-8 h-8 bg-black border border-gray-300 rounded-full overflow-clip"
                          />
                        </td>
                        <td className="p-2 text-left whitespace-nowrap">{`${user.firstName} ${user.lastName}`}</td>
                        <td className="p-2 text-left whitespace-nowrap">
                          <a href={`mailto:${user.email}`}>{user.email}</a>
                        </td>
                        <td className="p-2 text-left whitespace-nowrap">{user._id}</td>
                        <td className="p-2 text-center whitespace-nowrap">
                          {user.dateOfBirth ? (
                            format(new Date(user.dateOfBirth), 'MM/dd/yyyy')
                          ) : (
                            <span className="text-xs text-gray-300">No birth date</span>
                          )}
                        </td>
                        <td className="p-2 text-center whitespace-nowrap">
                          {user.location ? user.location : <span className="text-xs text-gray-300">No location</span>}
                        </td>
                        <td className="p-2 text-center whitespace-nowrap">
                          {user.createdAt ? (
                            format(new Date(user.createdAt), 'MM/dd/yyyy')
                          ) : (
                            <span className="text-xs text-gray-300">No registration date</span>
                          )}
                        </td>
                        <td className="p-2 text-center whitespace-nowrap">{user.soloChallenge?.numberOfCreated || 0}</td>
                        <td className="p-2 text-center whitespace-nowrap">{user.soloChallenge?.numberOfAccepted || 0}</td>
                        <td className="p-2 text-center whitespace-nowrap">{user.soloChallenge?.numberOfStarted || 0}</td>
                        <td className="p-2 text-center whitespace-nowrap">{user.soloChallenge?.numberOfCompleted || 0}</td>
                        <td className="p-2 text-center whitespace-nowrap">{user.seasonsParticipated}</td>
                        <td className="p-2 text-center whitespace-nowrap">
                          {user.totalPointsEarned ? user.totalPointsEarned.toFixed(2) : 0}
                        </td>
                        <td className="p-2 text-center whitespace-nowrap">{user.streak ? user.streak.toFixed(2) : 0}</td>
                        <td className="p-2 text-center whitespace-nowrap">{user.dailyAverage ? user.dailyAverage.toFixed(2) : 0}</td>
                        <td
                          className={`p-2 whitespace-nowrap text-center ${
                            user.notifications?.receive ? 'text-green-600' : 'text-red-600'
                          }`}>
                          {user.notifications?.receive.toString()}
                        </td>
                        <td className="p-2 text-center whitespace-nowrap">{user.trophies ? user.trophies.length : 0}</td>
                        <td className="p-2 text-right whitespace-nowrap">
                          {user.expireAt ? (
                            format(new Date(user.expireAt), 'MM/dd/yyyy')
                          ) : (
                            <span className="text-xs text-gray-300">No expire date</span>
                          )}
                        </td>
                        <td className="p-2 text-right whitespace-nowrap">
                          {user.updatedAt ? (
                            format(new Date(user.updatedAt), 'MM/dd/yyyy')
                          ) : (
                            <span className="text-xs text-gray-300">No updated date</span>
                          )}
                        </td>
                        <td className={`p-2 whitespace-nowrap text-center ${user.isActive ? 'text-green-600' : 'text-red-600'}`}>
                          {user.isActive && user.isActive.toString()}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-8 text-center text-gray-400" colSpan={20}>
                        No users in database
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                className="mt-8"
                currentPage={currentPage}
                totalCount={pageCount}
                pageSize={pageLimit}
                onPageChange={(page: number) => handlePageChange(page)}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default UsersList;
