import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { format, addMinutes } from 'date-fns';
import { SessionService, SeasonsService } from '../../services';
import { MultiTabSwitch, Sidebar } from '../../components';
import Pagination from 'src/components/Pagination';
import Skeleton from 'react-loading-skeleton';
import { Utils } from 'src/utils/Utils';

const SeasonsList = () => {
  const [seasons, setSeasons] = useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [seasonType, setSeasonType] = useState('Public');
  const [isLoading, setIsLoading] = useState(false);
  const isFirstRender = useRef(true);
  const pageLimit = 20;

  useEffect(() => {
    const getData = async () => {
      setIsLoading(() => true);
      const storedSeasonType = sessionStorage.getItem('seasonType');
      if (storedSeasonType) {
        setSeasonType(() => storedSeasonType);
      }

      const data = await SeasonsService.getAllSeasons(skip, pageLimit, storedSeasonType || 'Public');

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.results) {
        setSeasons(() => data.results);
        setPageCount(() => data.count);
      }

      isFirstRender.current = false;
      setIsLoading(() => false);
    };

    getData();
  }, []);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setSkip((page - 1) * pageLimit);
  };

  const handleSeasonList = (type: string) => {
    setSkip(() => 0);
    setCurrentPage(() => 1);
    setSeasonType(() => type);
    sessionStorage.setItem('seasonType', type);
  };

  useEffect(() => {
    if (!isFirstRender.current) {
      const getData = async () => {
        setIsLoading(() => true);
        const data = await SeasonsService.getAllSeasons(skip, pageLimit, seasonType);

        if (data.status === 500) {
          SessionService.clearSession();
        }

        setSeasons(() => []);
        if (data.results) {
          setSeasons(() => data.results);
          setPageCount(() => data.count);
        }
        setIsLoading(() => false);
      };

      getData();
    }
  }, [seasonType, currentPage, skip]);

  const exportUsersTo = async (seasonId: string, format: string) => {
    const res = await SeasonsService.exportUsers(seasonId);

    if (res) {
      Utils.export(res as Blob, `List_of_Users_By_Season_${seasonId}`, format);
    }
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <div className="flex items-center mt-2 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </svg>
                <h1 className="mr-4 text-xl font-semibold">Seasons</h1>

                {seasonType !== 'Self-Service' ? (
                  <Link
                    to={'/seasons/new'}
                    className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-4 h-4 mr-1"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                    Add new season
                  </Link>
                ) : (
                  <div className="p-4"></div>
                )}
              </div>

              <div className="w-[600px] mb-4">
                <MultiTabSwitch
                  options={[
                    { key: 'Public', value: 'Public' },
                    { key: 'Private', value: 'Private' },
                    { key: 'Self-Service', value: 'Self-Service' },
                  ]}
                  optionKey="key"
                  optionValue="value"
                  value={seasonType}
                  onChange={value => handleSeasonList(value)}
                />
              </div>

              {seasonType !== 'Self-Service' && (
                <table className="w-full table-auto">
                  <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                    <tr>
                      <th className="p-2 text-left whitespace-nowrap">Name</th>
                      <th className="p-2 text-center whitespace-nowrap">Status</th>
                      <th className="p-2 text-center whitespace-nowrap">Start date</th>
                      <th className="p-2 text-center whitespace-nowrap">Is private</th>
                      <th className="p-2 text-right whitespace-nowrap">Created at</th>
                      <th className="p-2 text-right whitespace-nowrap">Updated at</th>
                      <th className="p-2 text-right whitespace-nowrap">List of Users</th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-300">
                    {isLoading && (
                      <tr>
                        <td className="text-center text-gray-400" colSpan={7}>
                          <Skeleton height={50} count={20} />
                        </td>
                      </tr>
                    )}
                    {!isLoading && seasons.length > 0 ? (
                      seasons.map(season => (
                        <tr key={season._id}>
                          <td className="p-2 text-left whitespace-nowrap">
                            <Link to={`/seasons/${season._id}`}>{season.name}</Link>
                          </td>
                          <td className="p-2 text-center whitespace-nowrap">{season.status}</td>
                          <td className="p-2 text-center whitespace-nowrap">
                            {season.startDate
                              ? format(addMinutes(new Date(season.startDate), new Date(season.startDate).getTimezoneOffset()), 'MM/dd/yyyy')
                              : '-'}
                          </td>
                          <td className="items-center p-2 text-center whitespace-nowrap">
                            {season.privateSeason ? (
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-4 mx-auto opacity-25"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}>
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                                />
                              </svg>
                            )}
                          </td>
                          <td className="p-2 text-right whitespace-nowrap">
                            {season.createdAt
                              ? format(addMinutes(new Date(season.createdAt), new Date(season.createdAt).getTimezoneOffset()), 'MM/dd/yyyy')
                              : '-'}
                          </td>
                          <td className="p-2 text-right whitespace-nowrap">
                            {season.updatedAt
                              ? format(addMinutes(new Date(season.updatedAt), new Date(season.updatedAt).getTimezoneOffset()), 'MM/dd/yyyy')
                              : '-'}
                          </td>
                          <td className="p-2 flex justify-end space-x-2">
                            <button
                              onClick={() => exportUsersTo(season._id, 'csv')}
                              className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-1">
                                <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                                <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                              </svg>
                              .csv
                            </button>
                            <button
                              onClick={() => exportUsersTo(season._id, 'xlsx')}
                              className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-1">
                                <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                                <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                              </svg>
                              .xlsx
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-8 text-center text-gray-400" colSpan={7}>
                          No seasons in database
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}

              {seasonType === 'Self-Service' && (
                <table className="w-full table-auto">
                  <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                    <tr>
                      <th className="p-2 text-left whitespace-nowrap"></th>
                      <th className="p-2 text-left whitespace-nowrap">Name</th>
                      <th className="p-2 text-left whitespace-nowrap"></th>
                      <th className="p-2 text-left whitespace-nowrap">Description</th>
                      <th className="p-2 text-left whitespace-nowrap">League Code</th>
                      <th className="p-2 text-center whitespace-nowrap">Status</th>
                      <th className="p-2 text-center whitespace-nowrap">Teams count</th>
                      <th className="p-2 text-center whitespace-nowrap">Format</th>
                      <th className="p-2 text-center whitespace-nowrap">Team limit</th>
                      <th className="p-2 text-left whitespace-nowrap">Regular weeks</th>
                      <th className="p-2 text-left whitespace-nowrap">Playoff weeks</th>
                      <th className="p-2 text-center whitespace-nowrap">Is private</th>
                      <th className="p-2 text-right whitespace-nowrap">Start date</th>
                      <th className="p-2 text-right whitespace-nowrap">End date</th>
                      <th className="p-2 text-center whitespace-nowrap">End (h)</th>
                      <th className="p-2 text-center whitespace-nowrap">Timezone</th>
                      <th className="p-2 text-center whitespace-nowrap">Daily max</th>
                      <th className="p-2 text-center whitespace-nowrap">Team bonus</th>
                      <th className="p-2 text-left whitespace-nowrap">Bonuses</th>
                      <th className="p-2 text-right whitespace-nowrap">Created at</th>
                      <th className="p-2 text-right whitespace-nowrap">Updated at</th>
                      <th className="p-2 text-right whitespace-nowrap">List of Users</th>
                      <th className="p-2 text-right whitespace-nowrap"></th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-300">
                    {isLoading && (
                      <tr>
                        <td className="text-center text-gray-400" colSpan={23}>
                          <Skeleton height={50} count={20} />
                        </td>
                      </tr>
                    )}
                    {!isLoading && seasons.length > 0 ? (
                      seasons.map(league => (
                        <tr key={league._id}>
                          <td className="p-2 w-10 text-left whitespace-nowrap">
                            <img
                              alt={`${league.name}`}
                              src={league.photo}
                              className="object-cover max-w-max w-8 h-8 bg-black border border-gray-300 rounded-full overflow-clip"
                            />
                          </td>
                          <td className="p-2 text-left whitespace-nowrap">
                            <Link to={`/seasons/${league.season}/leagues/${league._id}`}>{`${league.name}`}</Link>
                          </td>
                          <td className="p-2 space-x-4 text-right whitespace-nowrap">
                            <Link to={`/seasons/${league.season}/leagues/${league._id}/matchups`}>Regular Season</Link>
                            {league.playoffBrackets?.length > 0 && (
                              <Link to={`/seasons/${league.season}/leagues/${league._id}/playoff`}>Playoffs</Link>
                            )}
                          </td>
                          <td className="p-2 text-left whitespace-nowrap">{league.description}</td>
                          <td className="p-2 text-left whitespace-nowrap">{league.passCode}</td>
                          <td className="p-2 text-center whitespace-nowrap">{league.status}</td>
                          <td className="p-2 text-center whitespace-nowrap">{league.teams?.length}</td>
                          <td className="p-2 text-center whitespace-nowrap">{league.format}</td>
                          <td className="p-2 text-center whitespace-nowrap">{league.teamLimit}</td>
                          <td className="p-2 text-left whitespace-nowrap">
                            <div>{league.regularWeeks?.numberOfWeeks} weeks</div>
                            <div>{league.regularWeeks?.weekLength} days/week</div>
                            <div>{league.regularWeeks?.flexibleOffDaysNumber} flexible off-days/week</div>
                          </td>
                          <td className="p-2 text-left whitespace-nowrap">
                            <div>bracket size is {league.playoffWeeks?.bracketSize}</div>
                            <div>{league.playoffWeeks?.numberOfWeeks} weeks</div>
                          </td>
                          <td className="items-center p-2 text-center whitespace-nowrap">
                            {league.privateLeague ? (
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-4 mx-auto opacity-25"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}>
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                                />
                              </svg>
                            )}
                          </td>
                          <td className="p-2 text-right whitespace-nowrap">
                            {league.startDate
                              ? format(addMinutes(new Date(league.startDate), new Date(league.startDate).getTimezoneOffset()), 'MM/dd/yyyy')
                              : '-'}
                          </td>
                          <td className="p-2 text-right whitespace-nowrap">
                            {league.endDate
                              ? format(addMinutes(new Date(league.endDate), new Date(league.endDate).getTimezoneOffset()), 'MM/dd/yyyy')
                              : '-'}
                          </td>
                          <td className="p-2 text-center whitespace-nowrap">{league.endHour}</td>
                          <td className="p-2 text-center whitespace-nowrap">{league.timezone}</td>
                          <td className="p-2 text-center whitespace-nowrap">{league.dailyMax}</td>
                          <td className="p-2 text-center whitespace-nowrap">{league.teamBonus}</td>
                          <td className="p-2 text-left whitespace-nowrap">
                            <div className={!league.bonuses?.water ? 'line-through text-gray-400' : undefined}>
                              water {league.bonuses?.water}
                            </div>
                            <div className={!league.bonuses?.alcohol ? 'line-through text-gray-400' : undefined}>
                              alcohol {league.bonuses?.alcohol}
                            </div>
                            <div className={!league.bonuses?.junkFood ? 'line-through text-gray-400' : undefined}>
                              junk food {league.bonuses?.junkFood}
                            </div>
                          </td>
                          <td className="p-2 text-right whitespace-nowrap">
                            {league.createdAt
                              ? format(addMinutes(new Date(league.createdAt), new Date(league.createdAt).getTimezoneOffset()), 'MM/dd/yyyy')
                              : '-'}
                          </td>
                          <td className="p-2 text-right whitespace-nowrap">
                            {league.updatedAt
                              ? format(addMinutes(new Date(league.updatedAt), new Date(league.updatedAt).getTimezoneOffset()), 'MM/dd/yyyy')
                              : '-'}
                          </td>
                          <td className="p-2 text-right whitespace-nowrap">
                            <div className="flex space-x-2">
                              <button
                                onClick={() => exportUsersTo(league.season, 'csv')}
                                className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-1">
                                  <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                                  <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                                </svg>
                                .csv
                              </button>
                              <button
                                onClick={() => exportUsersTo(league.season, 'xlsx')}
                                className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-1">
                                  <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
                                  <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                                </svg>
                                .xlsx
                              </button>
                            </div>
                          </td>
                          <td className="p-2 text-right">
                            <Link
                              to={`/seasons/${league.season}/leagues/${league._id}/edit`}
                              className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-4 h-4 mr-1"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2">
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                />
                              </svg>
                              Edit
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="p-8 text-center text-gray-400" colSpan={23}>
                          No leagues in database
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}

              <Pagination
                className="mt-8"
                currentPage={currentPage}
                totalCount={pageCount}
                pageSize={pageLimit}
                onPageChange={(page: number) => handlePageChange(page)}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SeasonsList;
