import React, { useEffect, useState } from 'react';
import DropDown from '../DropDown';
import { getISODay } from 'date-fns';
import { ReportService } from 'src/services';
import { useRecoilState } from 'recoil';
import { filterStore } from 'src/store/filterStore';

interface LeaderboardIndividualFilterProps {
  leagueStatus: string;
}

const LeaderboardIndividualFilter: React.FC<LeaderboardIndividualFilterProps> = ({ leagueStatus }) => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState<Array<any>>([]);
  const [activities, setActivities] = useState<Array<any>>([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedActivity, setSelectedActivity] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('');
  const [filters, setFilters] = useRecoilState<any>(filterStore);
  const [activeFilters, setActiveFilters] = useState(0);

  const getPeriodFilters = () => {
    if (leagueStatus === 'Completed') {
      return [{ value: 'Season Total', key: 9 }];
    } else {
      return [
        { value: 'Monday', key: 1 },
        { value: 'Tuesday', key: 2 },
        { value: 'Wednesday', key: 3 },
        { value: 'Thursday', key: 4 },
        { value: 'Friday', key: 5 },
        { value: 'Saturday', key: 6 },
        { value: 'Sunday', key: 7 },
        { value: 'Weekly Total', key: 8 },
        { value: 'Season Total', key: 9 },
      ];
    }
  };

  const periods = getPeriodFilters();

  useEffect(() => {
    const getData = async () => {
      if (!filters) {
        if (leagueStatus === 'Completed') {
          setSelectedPeriod(() => '9');
        } else {
          setSelectedPeriod(() => getISODay(new Date()).toString());
          setSelectedUnit('Pts');
        }
      }

      const data = await ReportService.getIndividualLeaderboardFilters();

      setCategories([]);
      if (data.results) {
        setData(() => data.results);
        data.results.map((category: any) => setCategories(categories => [...categories, { key: category._id, value: category._id }]));
      }
    };

    getData();
  }, [leagueStatus]);

  const selectCategory = (category: string) => {
    if (selectedCategory && selectedCategory === category) {
      setSelectedCategory('');
      setSelectedUnit('');
    } else {
      setSelectedCategory(() => category);
    }

    setSelectedActivity('');

    data.map((cat: any) => {
      if (cat._id === category) {
        setActivities([]);
        cat.activities.map((act: any) => setActivities(activities => [...activities, { key: act, value: act }]));
      }
    });
  };

  const selectActivity = (activity: string) => {
    if (selectedActivity && selectedActivity === activity) {
      setSelectedActivity('');
      setSelectedUnit('');
    } else {
      setSelectedActivity(activity);
      setSelectedUnit('DTR');
    }
  };

  const clearSelections = () => {
    setSelectedCategory('');
    setSelectedActivity('');
    if (leagueStatus === 'Completed') {
      setSelectedPeriod(() => '9');
      setFilters({ category: null, activity: null, day: '9', unit: null });
    } else {
      setSelectedPeriod(() => getISODay(new Date()).toString());
      setSelectedUnit('Pts');
      setFilters({ category: null, activity: null, day: null, unit: null });
    }
    setActiveFilters(0);
  };

  const applyFilters = () => {
    if (selectedCategory && !selectedActivity) {
      alert('Please select activity');
    } else {
      setFilters({ category: selectedCategory, activity: selectedActivity, /*day: selectedPeriod,*/ unit: selectedUnit });
      setActiveFilters(() => (selectedCategory ? 1 : 0) + (selectedActivity ? 1 : 0) /*+ (selectedPeriod ? 1 : 0)*/);
    }
  };

  useEffect(() => {
    if (filters) {
      setSelectedCategory(() => filters?.category || '');
      data.map((cat: any) => {
        if (cat._id === filters?.category) {
          setActivities([]);
          cat.activities.map((act: any) => {
            setActivities(activities => [...activities, { key: act, value: act }]);
          });
        }
      });
      setSelectedActivity(filters?.activity || '');
      if (filters?.day) {
        const period = leagueStatus === 'Completed' ? '9' : filters?.day;
        setSelectedPeriod(() => period);
      } else {
        // preselect period filter to current day on clear selections
        setSelectedPeriod(() => getISODay(new Date()).toString());
        setSelectedUnit('Pts');
      }
      setSelectedUnit(() => filters?.unit || '');
    }
  }, [data, filters, leagueStatus]);

  return (
    <div className="w-full border rounded-md p-2">
      {leagueStatus === 'Completed' && (
        <span className="block mb-2 text-xs text-red-600">* If the selected league is completed, only season total is available</span>
      )}
      <div className="flex flex-row space-x-2 mb-2">
        <DropDown
          label="Category"
          placeholder="Select Category"
          values={categories}
          value={selectedCategory}
          onChange={value => selectCategory(value)}
        />
        <DropDown
          label="Activity"
          placeholder="Select Activity"
          values={activities}
          value={selectedActivity}
          onChange={value => selectActivity(value)}
          disabled={!selectedCategory}
        />
        {/* <DropDown
          label="Period"
          placeholder="Select Period"
          values={periods}
          value={selectedPeriod}
          onChange={value => setSelectedPeriod(value)}
        /> */}
      </div>

      <div className="flex flex-row items-center space-x-2">
        <button
          className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white"
          onClick={() => applyFilters()}>
          Filter
        </button>
        <button
          className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white"
          onClick={() => clearSelections()}>
          Reset
        </button>
        {activeFilters > 0 && <span className="pl-2">Filters Applied: ({activeFilters})</span>}
      </div>
    </div>
  );
};

export default LeaderboardIndividualFilter;
