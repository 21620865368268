import { SessionService } from './SessionService';

const API_URL = process.env.REACT_APP_API_URL;

const getAllTeams = async (skip: number, limit: number) => {
  try {
    const response = await fetch(`${API_URL}/team?${new URLSearchParams({ skip: skip.toString(), limit: limit.toString() })}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: TeamService.js ~ getAllTeams ~ error', error);
    return { status: 500 };
  }
};

const getTeamEdit = async (id: string) => {
  try {
    const response = await fetch(`${API_URL}/team/${id}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: TeamService.js ~ getTeamEdit ~ error', error);
    return { status: 500 };
  }
};

const changeTeamName = async (teamId: string, name: string) => {
  const data = {
    name,
  };
  try {
    const response = await fetch(`${API_URL}/team/${teamId}/rename`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await SessionService.getSessionFromStorage()}`,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    console.log('🚀 ~ file: TeamService.js ~ changeTeamName ~ error', error);
    return { status: 500 };
  }
};

export const TeamService = {
  getAllTeams,
  getTeamEdit,
  changeTeamName,
};
