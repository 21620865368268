import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Matchup } from 'src/models';
import { SessionService } from '../services';
import { MatchupsService } from '../services/MatchupsService';
import MatchupItem from './MatchupItem';

interface MatchupWeekListProps {
  seasonWeek: number;
  teams: any;
  bracketSize: number;
  regularWeeksBrackets: any;
}

const MatchupWeekList: React.FC<MatchupWeekListProps> = ({ seasonWeek, teams, bracketSize, regularWeeksBrackets }) => {
  const { seasonId, leagueId } = useParams();
  const [matchups, setMatchups] = useState<Array<any>>([]);

  const handleAddMatchupItem = (seasonWeek: number): void => {
    if (matchups.length < bracketSize) {
      setMatchups(matchups => [...matchups, { id: '', seasonWeek: seasonWeek, teams: [] }]);
    }
  };

  const handleSaveMatchup = async (matchup: any, index: number) => {
    const data = await MatchupsService.addMatchup(seasonId!, leagueId!, matchup.teams, matchup.seasonWeek);

    if (data.status === 500) {
      SessionService.clearSession();
    }

    if (data.results) {
      const list: Array<Matchup> = [...matchups];
      list[index].id = data.results._id;
      list[index].seasonWeek = data.results.seasonWeek;
      list[index].teams = data.results.teams;
      setMatchups(() => list);
    }
  };

  const handleDeleteMatchup = async (matchupId: string, index: number) => {
    if (matchupId) {
      const data = await MatchupsService.deleteMatchup(seasonId!, leagueId!, matchupId);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data) {
        setMatchups(() => matchups.filter(matchup => matchup.id !== matchupId));
      }
    } else {
      if (matchups.length > 1) {
        const list: Array<Matchup> = [...matchups];
        list.splice(index, 1);
        setMatchups(() => list);
      }
    }
  };

  useMemo(() => {
    regularWeeksBrackets.map((bracket: { seasonWeek: number; _id: any; status: any; teams: any }) => {
      if (bracket.seasonWeek === seasonWeek) {
        setMatchups(matchups => [
          ...matchups,
          { id: bracket._id, seasonWeek: bracket.seasonWeek, status: bracket.status, teams: bracket.teams },
        ]);
      }
    });
  }, []);

  useEffect(() => {
    if (matchups.length === 0) {
      setMatchups([{ id: '', seasonWeek: seasonWeek, teams: [] }]);
    }
  }, [matchups]);

  return (
    <div className="mb-4 form-section">
      <h2 className="pb-2 mb-4 text-lg font-semibold border-b">Week {seasonWeek}</h2>
      <div>
        {matchups.map((matchup, index) => (
          <MatchupItem
            key={index}
            teams={teams}
            item={matchup}
            data={matchups}
            onSave={e => handleSaveMatchup(e, index)}
            onDelete={e => handleDeleteMatchup(e, index)}
          />
        ))}

        {matchups.length < bracketSize && (
          <button
            type="button"
            className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white"
            onClick={() => handleAddMatchupItem(seasonWeek)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-4 h-4 mr-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
            </svg>
            Add matchups
          </button>
        )}
      </div>
    </div>
  );
};

export default MatchupWeekList;
