import React, { useId } from 'react';
import './Switch.css';

interface SwitchProps {
  title: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

const Switch: React.FC<SwitchProps> = ({ title, value, onChange }) => {
  const id: string = useId();
  const isChecked: boolean = value ? true : false;

  const handleChange = () => {
    onChange(!value);
  };

  return (
    <div className="p-1 align-middle">
      <label htmlFor={id} className="text-xs font-bold">
        {title}
      </label>
      <div className="relative inline-block w-10 ml-2 align-middle transition duration-200 ease-in select-none">
        <input
          id={id}
          name={id}
          type="checkbox"
          className="absolute block w-6 h-6 bg-white border-2 rounded-full appearance-none cursor-pointer toggle-checkbox checked:right-0"
          onChange={handleChange}
          checked={isChecked}
        />
        <label htmlFor={id} className="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer toggle-label"></label>
      </div>
    </div>
  );
};

export default Switch;
