import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { format, addMinutes } from 'date-fns';
import { SessionService, SeasonsService } from '../../services';
import { Sidebar } from '../../components';
import Pagination from 'src/components/Pagination';

const SeasonDetails = () => {
  const navigate = useNavigate();
  const { seasonId } = useParams();
  const [leagues, setLeagues] = useState<Array<any>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [skip, setSkip] = useState(0);
  const [isPrivateSeason, setIsPrivateSeason] = useState(false);
  const pageLimit = 20;

  useEffect(() => {
    const getData = async () => {
      const data = await SeasonsService.getSeasonDetails(seasonId, skip, pageLimit);

      if (data.status === 500) {
        SessionService.clearSession();
      }

      if (data.season) {
        setIsPrivateSeason(() => data.season.privateSeason);
      }

      if (data.results) {
        setLeagues(() => data.results);
        setPageCount(() => data.count);
      }
    };

    getData();
  }, [seasonId, currentPage, skip]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setSkip((page - 1) * pageLimit);
  };

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar />
      <div className="flex flex-col flex-1 w-0 overflow-hidden">
        <main className="relative flex-1 overflow-y-auto focus:outline-none">
          <div className="py-6">
            <div className="w-full px-4 sm:px-6 md:px-8">
              <div className="flex items-center mt-2 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </svg>
                <h1 className="mr-4 text-xl font-semibold">Season Leagues</h1>
                <Link
                  to={`/seasons/${seasonId}/leagues/new`}
                  className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    />
                  </svg>
                  Add new league
                </Link>
              </div>

              <table className="w-full table-auto">
                <thead className="text-xs tracking-wide text-left bg-gray-100 border-b">
                  <tr>
                    <th className="p-2 text-left whitespace-nowrap"></th>
                    <th className="p-2 text-left whitespace-nowrap">Name</th>
                    <th className="p-2 text-left whitespace-nowrap"></th>
                    <th className="p-2 text-left whitespace-nowrap">Description</th>
                    {isPrivateSeason && <th className="p-2 text-left whitespace-nowrap">League Code</th>}
                    <th className="p-2 text-center whitespace-nowrap">Status</th>
                    <th className="p-2 text-center whitespace-nowrap">Teams count</th>
                    <th className="p-2 text-center whitespace-nowrap">Format</th>
                    <th className="p-2 text-center whitespace-nowrap">Team limit</th>
                    <th className="p-2 text-left whitespace-nowrap">Regular weeks</th>
                    <th className="p-2 text-left whitespace-nowrap">Playoff weeks</th>
                    <th className="p-2 text-center whitespace-nowrap">Is private</th>
                    <th className="p-2 text-right whitespace-nowrap">Start date</th>
                    <th className="p-2 text-right whitespace-nowrap">End date</th>
                    <th className="p-2 text-center whitespace-nowrap">End (h)</th>
                    <th className="p-2 text-center whitespace-nowrap">Timezone</th>
                    <th className="p-2 text-center whitespace-nowrap">Daily max</th>
                    <th className="p-2 text-center whitespace-nowrap">Team bonus</th>
                    <th className="p-2 text-left whitespace-nowrap">Bonuses</th>
                    <th className="p-2 text-right whitespace-nowrap">Created at</th>
                    <th className="p-2 text-right whitespace-nowrap">Updated at</th>
                    <th className="p-2 text-right whitespace-nowrap"></th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-300">
                  {leagues.length > 0 ? (
                    leagues.map(league => (
                      <tr key={league._id}>
                        <td className="p-2 w-10 text-left whitespace-nowrap">
                          <img
                            alt={`${league.name}`}
                            src={league.photo}
                            className="object-cover max-w-max w-8 h-8 bg-black border border-gray-300 rounded-full overflow-clip"
                          />
                        </td>
                        <td className="p-2 text-left whitespace-nowrap">
                          <Link to={`/seasons/${seasonId}/leagues/${league._id}`}>{`${league.name}`}</Link>
                        </td>
                        <td className="p-2 space-x-4 text-right whitespace-nowrap">
                          {/* <Link
                            to={`/seasons/${seasonId}/leagues/${league._id}/matchups`}
                            className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4 mr-1"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                              />
                            </svg>
                            Matchups
                          </Link> */}
                          <Link to={`/seasons/${seasonId}/leagues/${league._id}/matchups`}>Regular Season</Link>
                          {league.playoffBrackets.length > 0 && (
                            <Link to={`/seasons/${seasonId}/leagues/${league._id}/playoff`}>Playoffs</Link>
                          )}
                        </td>
                        <td className="p-2 text-left whitespace-nowrap">{league.description}</td>
                        {isPrivateSeason && <td className="p-2 text-left whitespace-nowrap">{league.passCode}</td>}
                        <td className="p-2 text-center whitespace-nowrap">{league.status}</td>
                        <td className="p-2 text-center whitespace-nowrap">{league.teams.length}</td>
                        <td className="p-2 text-center whitespace-nowrap">{league.format}</td>
                        <td className="p-2 text-center whitespace-nowrap">{league.teamLimit}</td>
                        <td className="p-2 text-left whitespace-nowrap">
                          <div>{league.regularWeeks.numberOfWeeks} weeks</div>
                          <div>{league.regularWeeks.weekLength} days/week</div>
                          <div>{league.regularWeeks.flexibleOffDaysNumber} flexible off-days/week</div>
                        </td>
                        <td className="p-2 text-left whitespace-nowrap">
                          <div>bracket size is {league.playoffWeeks.bracketSize}</div>
                          <div>{league.playoffWeeks.numberOfWeeks} weeks</div>
                        </td>
                        <td className="items-center p-2 text-center whitespace-nowrap">
                          {league.privateLeague ? (
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                              <path
                                fillRule="evenodd"
                                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4 mx-auto opacity-25"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
                              />
                            </svg>
                          )}
                        </td>
                        <td className="p-2 text-right whitespace-nowrap">
                          {league.startDate
                            ? format(addMinutes(new Date(league.startDate), new Date(league.startDate).getTimezoneOffset()), 'MM/dd/yyyy')
                            : '-'}
                        </td>
                        <td className="p-2 text-right whitespace-nowrap">
                          {league.endDate
                            ? format(addMinutes(new Date(league.endDate), new Date(league.endDate).getTimezoneOffset()), 'MM/dd/yyyy')
                            : '-'}
                        </td>
                        <td className="p-2 text-center whitespace-nowrap">{league.endHour}</td>
                        <td className="p-2 text-center whitespace-nowrap">{league.timezone}</td>
                        <td className="p-2 text-center whitespace-nowrap">{league.dailyMax}</td>
                        <td className="p-2 text-center whitespace-nowrap">{league.teamBonus}</td>
                        <td className="p-2 text-left whitespace-nowrap">
                          <div className={!league.bonuses.water ? 'line-through text-gray-400' : undefined}>
                            water {league.bonuses.water}
                          </div>
                          <div className={!league.bonuses.alcohol ? 'line-through text-gray-400' : undefined}>
                            alcohol {league.bonuses.alcohol}
                          </div>
                          <div className={!league.bonuses.junkFood ? 'line-through text-gray-400' : undefined}>
                            junk food {league.bonuses.junkFood}
                          </div>
                        </td>
                        <td className="p-2 text-right whitespace-nowrap">
                          {league.createdAt
                            ? format(addMinutes(new Date(league.createdAt), new Date(league.createdAt).getTimezoneOffset()), 'MM/dd/yyyy')
                            : '-'}
                        </td>
                        <td className="p-2 text-right whitespace-nowrap">
                          {league.updatedAt
                            ? format(addMinutes(new Date(league.updatedAt), new Date(league.updatedAt).getTimezoneOffset()), 'MM/dd/yyyy')
                            : '-'}
                        </td>
                        <td className="p-2 text-right">
                          <Link
                            to={`/seasons/${seasonId}/leagues/${league._id}/edit`}
                            className="flex p-2 text-xs text-white no-underline bg-black rounded-md hover:bg-gray-800 hover:text-white">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-4 h-4 mr-1"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                              />
                            </svg>
                            Edit
                          </Link>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className="p-8 text-center text-gray-400" colSpan={22}>
                        No seasons in database
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <Pagination
                className="mt-8"
                currentPage={currentPage}
                totalCount={pageCount}
                pageSize={pageLimit}
                onPageChange={(page: number) => handlePageChange(page)}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SeasonDetails;
