import React from 'react';
import { Utils } from '../utils/Utils';

// TODO: missing interface MatchupPlayoffsBrackets, Bracket, Team

interface MatchupPlayoffsBracketsProps {
  data: any; // TODO: data should be of type MatchupPlayoffsBrackets
}

const MatchupPlayoffsBrackets: React.FC<MatchupPlayoffsBracketsProps> = ({ data }) => {
  return data.map(
    (item: any) =>
      item.brackets.length > 0 && (
        <div key={item._id} className="w-full">
          <p className="mb-4 text-lg font-semibold">{item.title}</p>

          {item.brackets.map((bracket: any) => (
            <div key={bracket._id} className="mb-4 form-section">
              {bracket.totalPoints.map((team: any) => (
                <div
                  key={team._id}
                  className="flex items-center justify-between w-full px-3 py-2 -space-y-px text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm sm:text-sm">
                  <div className="flex items-center">
                    <img
                      alt={team.team?.name}
                      src={team.team?.photo}
                      className="object-cover w-8 h-8 mr-2 bg-black border border-gray-300 rounded-full overflow-clip"
                    />
                    <p>{team.team?.name}</p>
                  </div>

                  <p>{Utils.roundNumber(team.points)}</p>
                </div>
              ))}

              {bracket.totalPoints.length === 0 &&
                [...Array(item.playoffSize).keys()].map(team => (
                  <div
                    key={team}
                    className="flex justify-between w-full px-3 py-3.5 border border-gray-300 rounded-md shadow-sm -space-y-px bg-white text-gray-300 sm:text-sm">
                    <p>Winning Team</p>
                  </div>
                ))}
            </div>
          ))}
        </div>
      )
  );
};

export default MatchupPlayoffsBrackets;
